import React from 'react'

const Mensaje = ({etiqueta, mensaje}) =>{
  return(
    <div>
    {
      etiqueta === 'error' ?
        <div className="alert alert-danger fade show" role="alert">
        {mensaje}
        </div>
      : 
      etiqueta === 'success' ?
      <div className="alert alert-success fade show" role="alert">
        {mensaje}
      </div>
      : 
      <div className="alert alert-primary fade show" role="alert">
        {mensaje}
      </div>
      }
    </div>
  )
}

export default Mensaje