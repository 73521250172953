import React, { useContext, useState } from 'react';
import { AppContext } from '../contextApp'

const Total = ()=> {
  const { 
    venta, 
   } = useContext(AppContext);
  return (
    <div className="col-lg-4 col-md-12 col-sm-12 mb-4 offset-md-8">
      <div className="card card-small">
        <div className="card-body pt-0">
          <div className="row pt-3">
            <div className="col-md-6">TOTAL: </div><div className="col-md-6 text-right"><b> $ {(venta.subtotal).toFixed(2)}</b></div>
            {/* <div className="col-md-6">IVA: </div><div className="col-md-6 text-right"> $ {(venta.iva).toFixed(2)}</div>
            <div className="col-md-6">TOTAL: </div><div className="col-md-6 text-right"><strong> $ {(venta.total).toFixed(2)}</strong></div> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Total
