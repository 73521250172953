import React, { useEffect, useState, useContext } from 'react'
import { getData, deleteItem, insertActivity } from '../helpers'
import FormularioCompra from './FormularioCompra'
import FormularioDetalleCompra from './FormularioDetalleCompra'
import TablaDetalle from './TablaDetalle'
import { AppContext } from '../contextApp'

const ContainerCategorias = () =>{  
  const { dominio } = useContext(AppContext);
  const [proveedores, setProveedores] = useState([]);
  const [productos, setProductos] = useState([]);
  const [productos2, setProductos2] = useState([]);
  const [sucursales, setSucursales] = useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getProveedores =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('proveedores');
    console.log(send)
    let list_proveedores = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_proveedores.push({
          value: item.id,
          label: item.nombre_comercial
        })
      })
      setProveedores(list_proveedores);
    }
  }

  const getProductos =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('productos');
    setProductos2(send.data);
    let list_productos = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_productos.push({
          value: item.id,
          label: 'Código: ' + item.codigo + ' - Nombre: '+item.nombre_producto,
          status_lote: item.status_lote,
          status_caducidad: item.status_caducidad
        })
      })
      console.log(list_productos)
      setProductos(list_productos);
    }
  }

  const getSucursales =  async () =>{
    const send = await getData('sucursales');
    console.log(send)
    if(send.code === 200){
      setSucursales(send.data);
    }
  }



  useEffect(()=>{
    getProveedores();
    getProductos();
    getSucursales();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FormularioCompra 
          proveedores = {proveedores}
        />
        <FormularioDetalleCompra
          productos = {productos}
          productos2 = {productos2}
          sucursales = {sucursales}
        />
        <TablaDetalle
        />
      </div>
    </div>
  )
}

export default ContainerCategorias