import React, { useState, useEffect } from 'react'
import { getData, insertActivity } from '../helpers'
import Tabla from './Tabla'

const ReporteInventario = () =>{
  const [compras, setCompras] = useState([]);

  const getInventario =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    const send = await getData('historial-compras/'+user.empresa_id);
    console.log(send)
    if(send.code === 200){
      setCompras(send.data);
    }
    insertActivity(user.name, `Consultó inventario`, 'Reporte inventario', 'label-blue');
  }

  useEffect(()=>{
    getInventario();
  },[]);
  return(
    <div className="app-main__inner">           
      <div className="row">
        <Tabla 
          compras = {compras}
        />
      </div>
    </div>
  )
}

export default ReporteInventario