import React, { useEffect, useState } from 'react'
import { getData, deleteItem, insertActivity } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'


const ContainerUnidadMedida = () =>{  
  const [sucursales, setScursales]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [sucursal, setSucursal] = useState({
    nombre_sucursal:'',
    direccion_sucursal:'',
    correo_sucursal:'',
    tel_sucursal:''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  const getSucursales =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('sucursales');
    console.log(send)
    setScursales(send.data);
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setSucursal(data);
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('sucursales/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la sucursal',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getSucursales();
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó Sucursal [${send.data.nombre_sucursal}]`, 'Sucursal', 'label-red');
    }
  }

  useEffect(()=>{
    getSucursales();

  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getSucursales = {getSucursales}
          setSucursal = {setSucursal}
          sucursal = {sucursal}

          setActualizar = {setActualizar}
          actualizar = {actualizar}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          sucursales = {sucursales}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default ContainerUnidadMedida