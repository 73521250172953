import React, { useContext, useState } from 'react'
import DataTable from 'react-data-table-component'
import MaterialTable from 'material-table';
import Mensaje from '../Mensaje'

import { AppContext } from '../contextApp'

const Tabla = ({listaBusqueda, categoria, actualiza, mostrarmensaje2, eliminaItem, botoneliminar, setBotonEliminar, actualizaStateBusqueda, campobusca}) =>{
  const { dominio } = useContext(AppContext);

  const columnas = [
    {
      name: 'Código',
      selector : 'codigo',
    },
    {
      name: 'Nombre',
      selector : 'nombre_producto',
    },
    {
      name: 'Descripción',
      selector : 'desc_producto',
    },
    {
      name: 'Precio',
      selector : 'precio_unitario',
    },
    {
      name: 'Foto',
      cell: (row) => row.img ? <div><img style={{ width: 75, height: 55, alignSelf: 'center', margin: 5 }} src={`${dominio}/${row.img}`} /></div>: null,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
    {
      name: '',
      cell: (row) => <div>
                        { row.disponible ==='no_disponible' ?
                          <li className="fa fa-eye-slash text-default" style={{marginRight: 8}}></li>
                          : 
                          <li className="fa fa-eye text-info" style={{marginRight: 8}}></li> 
                        }
                        <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
                          <li className="fa fa-pencil"></li> 
                        </button>
                        <button onClick={()=>setBotonEliminar(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
                          <li className="fa fa-trash"></li>
                        </button>
                        {
                          botoneliminar ?
                          <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
                            Confirmar
                          </button>
                          : null
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },  
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }


  const [columnasproductos, setColumnasProductos] = useState(
    [
    { title: "Código", field: "codigo" },
    { title: "Producto", field: "nombre_producto" },
    { title: "Descripción", field: "desc_producto" },
    { title: "Precio", field: "precio_unitario" },
    {
      title: "",
      render: (row)=> 
                <div>
                    { row.disponible ==='no_disponible' ?
                      <li className="fa fa-eye-slash text-default" style={{marginRight: 8}}></li>
                      : 
                      <li className="fa fa-eye text-info" style={{marginRight: 8}}></li> 
                    }
                    <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
                      <li className="fa fa-pencil"></li> 
                    </button>
                    <button onClick={()=>setBotonEliminar(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
                      <li className="fa fa-trash"></li>
                    </button>
                    {
                      botoneliminar ?
                      <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
                        Confirmar
                      </button>
                      : null
                    }
                </div>
    }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            LISTA DE PRODUCTOS POR CATEGORÍA ({categoria})
          </div>
        </div>
        <div className="card-body col-md-12">
        <input 
          type="text" 
          value={campobusca}
          placeholder="Escriba un código, nombre de producto, descripción" 
          className="form-control form-control-sm"
          onChange = {actualizaStateBusqueda}
          />
        { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
          <DataTable
              columns= {columnas}
              noHeader
              data={listaBusqueda}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
            {/* <MaterialTable
              options={{
                headerStyle:{fontSize:'12px'},
                bodyStyle:{fontSize:'12px'},
                pageSize: 10
              }}
              title=""
              columns={columnasproductos}
              data={productos}
              onRowSelected
          /> */}
        </div>
      </div>
    </div>
  )
}

export default Tabla