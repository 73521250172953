import React, { useState } from 'react'
import { insertData, insertActivity } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getUnidades, setUnidad, unidad, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const { desc_unidad_medida } = unidad;

  const actualizaState = e =>{
    setUnidad({
      ...unidad,
      [e.target.name] : e.target.value
    })
  } 


  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setUnidad({
      desc_unidad_medida: ''
    })
    getUnidades();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(desc_unidad_medida ===''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }


    let formData = new FormData();
    formData.append('desc_unidad_medida', unidad.desc_unidad_medida);

    let ruta = 'unidad-medida';
    if(!actualizar){
      //Guardar
      const send = await insertData(ruta,formData);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Alta Unidad de medida [${unidad.desc_unidad_medida}]`, 'Unidad de medida', 'label-blue');
        reiniciarForm();
      }
    }else{
      //Actualizar
      formData.append('_method', 'PUT');
      const send = await insertData(ruta+'/'+unidad.id,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Editó Unidad de medida [${unidad.desc_unidad_medida}]`, 'Unidad de medida', 'label-orange');
        reiniciarForm();
      }
    }

  }

  return(
    <div className="col-md-5">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            UNIDADES DE MEDIDA
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Descripción(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "desc_unidad_medida"
              value = {desc_unidad_medida}
              onChange = {actualizaState}
              />
              {
                actualizar ?
                <button className="mt-1 btn btn-warning">Actualizar unidad</button>
                :
                <button className="mt-1 btn btn-primary">Guardar unidad</button>
              }
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario