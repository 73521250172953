import React, { useEffect, useState } from 'react'

const Header = () => {
    const [tipo, setTipo] = useState('');
    const [usuario, setUsuario] = useState({});
    useEffect( async ()=>{
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        if(user){
            setTipo(user.tipo);
            setUsuario(user);
        }
      },[]);
  return(
    <div className="app-header header-shadow">
      <div className="app-header__logo">
          <div className="logo-src"></div>
          <div className="header__pane ml-auto">
              <div>
                  <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                      <span className="hamburger-box">
                          <span className="hamburger-inner"></span>
                      </span>
                  </button>
              </div>
          </div>
      </div>
      <div className="app-header__mobile-menu">
          <div>
              <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                  <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                  </span>
              </button>
          </div>
      </div>
      <div className="app-header__menu">
          <span>
              <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                  <span className="btn-icon-wrapper">
                      <i className="fa fa-ellipsis-v fa-w-6"></i>
                  </span>
              </button>
          </span>
      </div>    
      <div className="app-header__content">
          {
              tipo !== 'empresa-repartidor' ?
             <div className="app-header-left">
              <div className="search-wrapper">
                <div className="input-holder">
                    <input type="text" className="search-input" placeholder="Type to search"/>
                    <button className="search-icon"><span></span></button>
                </div>
                <button className="close"></button>
            </div>
                <ul className="header-menu nav">
                    {/* <li className="nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                            <i className="nav-link-icon fa fa-database"> </i>
                            Estadistícas
                        </a>
                    </li> */}
                    <li className="btn-group nav-item">
                        <a href="javascript:void(0);" className="nav-link">
                            <i className="nav-link-icon fa fa-edit"></i>
                            Reportes
                        </a>
                    </li>
                    {/* <li className="dropdown nav-item">
                        <a href="./sucursales" className="nav-link">
                            <i className="nav-link-icon fa fa-building"></i>
                            Cambiar de sucursal
                        </a>
                    </li> */}
                </ul>        
            </div>
              :
              null
          }
          <div className="app-header-right">
              <div className="header-btn-lg pr-0">
                  <div className="widget-content p-0">
                      <div className="widget-content-wrapper">
                          <div className="widget-content-left">
                              <div className="btn-group">
                                  <a data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="p-0 btn">
                                      <img width="42" className="rounded-circle" src="assets/images/avatars/1.jpg" alt=""/>
                                      <i className="fa fa-angle-down ml-2 opacity-8"></i>
                                  </a>
                                  <div tabindex="-1" role="menu" aria-hidden="true" className="dropdown-menu dropdown-menu-right">
                                  <a className="nav-link" href="/login"><i className="fa fa-power -off"></i>Cerrar sesión</a>
                                      {/* <button type="button" tabindex="0" className="dropdown-item">Settings</button>
                                      <h6 tabindex="-1" className="dropdown-header">Header</h6>
                                      <button type="button" tabindex="0" className="dropdown-item">Actions</button>
                                      <div tabindex="-1" className="dropdown-divider"></div>
                                      <button type="button" tabindex="0" className="dropdown-item">Dividers</button> */}
                                  </div>
                              </div>
                          </div>
                          <div className="widget-content-left  ml-3 header-user-info">
                              <div className="widget-heading">
                                  {usuario.name}
                              </div>
                              <div className="widget-subheading">
                                  {usuario.tipo}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>        
            </div>
      </div>
    </div>    
  )
}

export default Header