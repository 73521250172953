import React, {useContext} from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../Mensaje'

import { AppContext } from '../contextApp'

const Tabla = ({usuarios, actualiza, mostrarmensaje2, botoneliminar, setBotonEliminar, eliminaItem, sucursales}) =>{
  const { dominio } = useContext(AppContext);

  const columnas = [
    {
      name: 'Nombre',
      selector : 'name',
    },
    {
      name: 'Correo',
      selector : 'email',
    },
    {
      name: 'Sucursal',
      cell: (row)=> <div>
        {
        row.empresa_id === 0 ?
          <label>Todas</label>
        :
        sucursales.map(suc => {
          if (suc.id === row.empresa_id){
            return suc.nombre_sucursal
          }
        })}
      </div>
    },
    {
      name: 'Tipo',
      selector : 'tipo',
    },
    {
      name: '',
      cell: (row) => <div>
                        { 
                        row.admin === 'true' ? null
                          :
                        parseInt(row.status) === 0 ?
                          <button onClick={()=>actualiza(row, 1)} type="button" className="btn btn-danger btn-sm" data-tip="Editar">
                            <li className="fa fa-lock"></li> 
                          </button>
                          : 
                          <button onClick={()=>actualiza(row, 0)} type="button" className="btn btn-success btn-sm" data-tip="Editar">
                            <li className="fa fa-unlock"></li> 
                          </button>


                        }
                        {
                          row.admin === 'true' ? null
                          :
                          <button onClick={()=>setBotonEliminar(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
                            <li className="fa fa-trash"></li>
                          </button>
                        }
                        {
                          row.admin === 'true' ? null
                          :
                            botoneliminar ?
                            <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
                              Confirmar
                            </button>
                            : null
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },  
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div class="col-md-12">
      <div class="card mb-3">
        <div class="card-header-tab card-header">
          <div class="card-header-title">
            LISTA DE USUARIOS
          </div>
        </div>
        <div class="card-body">
          { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
          <DataTable
              columns= {columnas}
              noHeader
              data={usuarios}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla