  import React, { useState } from 'react'
  import MaterialTable from 'material-table';
  import { CSVLink } from "react-csv";
  
  const Tabla = ({ transferencia }) =>{
  
    const paginacionOpciones={
      rowsPerPageText : 'Filas por paginas',
      rangeSeparatorText: 'de',
      selectAllRowsItem: true,
      selectAllRowsItemText: 'Todos',
    }
  
    const headers_transferencia = [
      { label: "Código", key: "codigo" },
      { label: "Producto", key: "nombre_producto" },
      { label: "Cantidad", key: "cantidad" },
      { label: "Origen", key: "nombre_sucursal_origen" },
      { label: "Destino", key: "nombre_sucursal_destino" },
      { label: "Fecha", key: "fecha_transferencia" }
    ];
  
    const [columnastransferencia, setColumnasTransferencia] = useState(
      [
        { title: "Código", field: "codigo" },
        { title: "Producto", field: "nombre_producto" },
        { title: "Cantidad", field: "cantidad" },
        { title: "Origen", field: "nombre_sucursal_origen" },
        { title: "Destino", field: "nombre_sucursal_destino" },
        { title: "Fecha", field: "fecha_transferencia" }
      ]
    );
  
    return(
      <div className="col-md-12">
        <div className="offset-md-10 col-md-2 text-right">
          <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
              <li className="fa fa-cloud-download"></li> <CSVLink data={transferencia} headers={headers_transferencia} filename={"Inventario.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
            </button> 
        </div>
      <MaterialTable
        options={{
          headerStyle:{fontSize:'12px'},
          bodyStyle:{fontSize:'12px'},
          pageSize: 10
        }}
        title="Historial Transferencia"
        columns={columnastransferencia}
        data={transferencia}
        onRowSelected
    />
    </div>
    )
  }
  
  export default Tabla
