import React, { useEffect, useState } from 'react'

const Sidebar = ({ despliega, active }) => {
  const [nombresucursal, setNombreSucursal]= useState('');
  const [tipo, setTipo] = useState('');

  useEffect( async ()=>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    if(user){
      setTipo(user.tipo);
    }else{
      window.location = '/login'
    }
  },[]);


  return(
    <div className="app-sidebar sidebar-shadow">
      <div className="app-header__logo">
          <div className="logo-src"></div>
          <div className="header__pane ml-auto">
              <div>
                  <button type="button" className="hamburger close-sidebar-btn hamburger--elastic" data-class="closed-sidebar">
                      <span className="hamburger-box">
                          <span className="hamburger-inner"></span>
                      </span>
                  </button>
              </div>
          </div>
      </div>
      <div className="app-header__mobile-menu">
          <div>
              <button type="button" className="hamburger hamburger--elastic mobile-toggle-nav">
                  <span className="hamburger-box">
                      <span className="hamburger-inner"></span>
                  </span>
              </button>
          </div>
      </div>
      <div className="app-header__menu">
          <span>
              <button type="button" className="btn-icon btn-icon-only btn btn-primary btn-sm mobile-toggle-header-nav">
                  <span className="btn-icon-wrapper">
                      <i className="fa fa-ellipsis-v fa-w-6"></i>
                  </span>
              </button>
          </span>
      </div>    
      <div className="scrollbar-sidebar">
        <div className="app-sidebar__inner">
              <ul className="vertical-nav-menu">
                <li className="app-sidebar__heading">AdaptingStock</li>
                <li>
                  <a href="/home" className="mm-active">
                    <i className="metismenu-icon pe-7s-rocket"></i>
                      Dashboard 
                  </a>
                </li>
                <li className="app-sidebar__heading">{nombresucursal}</li>
                { tipo === 'Vendedor' ||  tipo === 'Gerente' || tipo === 'Administrador' ?
                  <li class= {despliega === 1 ? "mm-active" : null } >
                    <a href="#">
                      <i className="metismenu-icon fa fa-barcode"></i>
                        Ventas
                      <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                    </a>
                      <ul>
                        { tipo === 'Vendedor' ||  tipo === 'Gerente' ?
                        <li>
                          <a href="ventas" class= {active === 1 ? "mm-active" : null }>
                            <i className="metismenu-icon"></i>
                              Nueva venta
                          </a>
                        </li>
                        : null
                        }
                        <li>
                          <a href="historial-ventas" class= {active === 16 ? "mm-active" : null }>
                            <i className="metismenu-icon">
                            </i>Historial
                          </a>
                        </li>
                      </ul>
                  </li>
                : null
                }
                { tipo === 'Capturista' ||  tipo === 'Gerente' || tipo === 'Gerente General' || tipo === 'Administrador' ?
                <li class= {despliega === 2 ? "mm-active" : null } >
                  <a href="#">
                    <i className="metismenu-icon fa fa-shopping-basket"></i>
                      Productos
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                  </a>
                    <ul>
                      <li>
                        <a href="productos" class= {active === 2 ? "mm-active" : null }>
                          <i className="metismenu-icon"></i>
                            Productos
                        </a>
                      </li>
                      <li>
                        <a href="lista-productos" class= {active === 17 ? "mm-active" : null }>
                          <i className="metismenu-icon"></i>
                            Lista de productos
                        </a>
                      </li>
                      {  tipo === 'Gerente' || tipo === 'Gerente General' || tipo === 'Administrador' ?
                      <li>
                        <a href="transferir" class= {active === 3 ? "mm-active" : null }>
                          <i className="metismenu-icon">
                          </i>Transferir
                        </a>
                      </li>
                      : null
                      }
                      <li>
                        <a href="historial-transferencia" class= {active === 4 ? "mm-active" : null }>
                          <i className="metismenu-icon">
                          </i>Historial transferencia
                        </a>
                      </li>
                    </ul>
                </li>
                   : null
                }
                { tipo === 'Capturista' ||  tipo === 'Gerente' || tipo === 'Gerente General' || tipo === 'Administrador' ?
                <li class= {despliega === 3 ? "mm-active" : null } >
                  <a href="#">
                    <i className="metismenu-icon fa fa-arrow-circle-down"></i>
                      Compras
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                  </a>
                    <ul>
                    <li>
                        <a href="compras" class= {active === 5 ? "mm-active" : null }>
                          <i className="metismenu-icon"></i>
                            Nueva Compra
                        </a>
                      </li>
                      <li>
                        <a href="historial-compras" class= {active === 6 ? "mm-active" : null }>
                          <i className="metismenu-icon"></i>
                            Historial
                        </a>
                      </li>
                    </ul>
                </li>
                : null
                }
                { tipo === 'Capturista' ||  tipo === 'Gerente' || tipo === 'Gerente General' || tipo === 'Administrador' ?
                <li class= {despliega === 4 ? "mm-active" : null } >
                  <a href="#">
                    <i className="metismenu-icon fa fa-th-list"></i>
                      Catálogos
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                  </a>
                    <ul>
                      <li>
                        <a href="unidad-medida" class= {active === 7 ? "mm-active" : null }>
                          <i className="metismenu-icon"></i>
                            Unidad de medida
                        </a>
                      </li>
                      <li>
                        <a href="categorias" class= {active === 8 ? "mm-active" : null }>
                          <i className="metismenu-icon">
                          </i>Categorías
                        </a>
                      </li>
                      <li>
                        <a href="clientes" class= {active === 9 ? "mm-active" : null }>
                          <i className="metismenu-icon">
                          </i>Clientes
                        </a>
                      </li>
                      <li>
                        <a href="proveedores" class= {active === 10 ? "mm-active" : null }>
                          <i className="metismenu-icon">
                          </i>Proveedores
                        </a>
                      </li>
                    </ul>
                </li>
                : null
                }
                <li class= {despliega === 5 ? "mm-active" : null } >
                  <a href="#">
                    <i className="metismenu-icon fa fa-tasks"></i>
                      Inventario
                    <i className="metismenu-state-icon pe-7s-angle-down caret-left"></i>
                  </a>
                    <ul>
                    <li>
                        <a href="reporte-inventario" class= {active === 11 ? "mm-active" : null }>
                          <i className="metismenu-icon fa fa-file"></i>
                            Reporte
                        </a>
                      </li>
                      {/* <li>
                        <a href="validar-inventario" class= {active === 16 ? "mm-active" : null }>
                          <i className="metismenu-icon fa fa-history"></i>
                            Validar
                        </a>
                      </li> */}
                    </ul>
                </li>
                { tipo === 'Capturista' ||  tipo === 'Gerente' || tipo === 'Gerente General' || tipo === 'Administrador' ?
                <li>
                  <a href="sucursales" class= {active === 13 ? "mm-active" : null }>
                    <i className="metismenu-icon fa fa-building"></i>
                    Sucursales
                  </a>
                </li>
                : null
                }
                { tipo === 'Administrador' ?
                <li>
                  <a href="usuarios" class= {active === 14 ? "mm-active" : null }>
                    <i className="metismenu-icon fa fa-users"></i>
                    Usuarios
                  </a>
                </li>
                : null
                }
                { tipo === 'Administrador' ?
                <li>
                  <a href="actividad" class= {active === 15 ? "mm-active" : null }>
                    <i className="metismenu-icon fa fa-history"></i>
                    Actividad
                  </a>
                </li>
                : null
                }
              </ul>
        </div>
      </div>
    </div>
  )
}

export default Sidebar