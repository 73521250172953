import React, { Fragment, useState } from 'react'
import { insertData, insertActivity } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getSucursales, setSucursal, sucursal, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const { 
    nombre_sucursal,
    direccion_sucursal,
    correo_sucursal,
    tel_sucursal,
  } = sucursal;

  const actualizaState = e =>{
    setSucursal({
      ...sucursal,
      [e.target.name] : e.target.value
    })
  } 


  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setSucursal({
      nombre_sucursal:'',
      direccion_sucursal:'',
      correo_sucursal:'',
      tel_sucursal:''
    })
    getSucursales();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(nombre_sucursal ==='' || tel_sucursal ==='' || direccion_sucursal===''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }


    let formData = new FormData();
    formData.append('nombre_sucursal', sucursal.nombre_sucursal);
    formData.append('direccion_sucursal', sucursal.direccion_sucursal);
    formData.append('correo_sucursal', sucursal.correo_sucursal);
    formData.append('tel_sucursal', sucursal.tel_sucursal);

    let ruta = 'sucursales';
    if(!actualizar){
      //Guardar
      const send = await insertData(ruta,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Alta Sucursal [${sucursal.nombre_sucursal}]`, 'Sucursal', 'label-blue');
        reiniciarForm();
      }
    }else{
      //Actualizar
      formData.append('_method', 'PUT');
      const send = await insertData(ruta+'/'+sucursal.id,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Editó Sucursal [${sucursal.nombre_sucursal}]`, 'Sucursal', 'label-orange');
        reiniciarForm();
      }
    }

  }

  return(
  <Fragment>
    <div className="col-md-6">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            SUCURSALES
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Nombre(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "nombre_sucursal"
              value = {nombre_sucursal}
              onChange = {actualizaState}
              required
              />
            <label>Dirección(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "direccion_sucursal"
              value = {direccion_sucursal ? direccion_sucursal : ''}
              onChange = {actualizaState}
              required
              />
            <label>Teléfono</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "tel_sucursal"
              value = {tel_sucursal}
              onChange = {actualizaState}
              required
              />
            <label>Correo</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "correo_sucursal"
              value = {correo_sucursal}
              onChange = {actualizaState}
              />
              {
                actualizar ?
                <button className="mt-1 btn btn-warning">Actualizar sucursal</button>
                :
                <button className="mt-1 btn btn-primary">Guardar sucursal</button>
              }
          </form>
        </div>
      </div>
    </div>
  </Fragment>
  )
}

export default Formulario