import React, { useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { getData } from '../helpers'

const Tabla = () =>{
  const [actividad, setActividad] = useState([]);
  const getHistorialMovimientos =  async () =>{
    const send = await getData('actividad');
    console.log(send)
    if(send.code===200){
      setActividad(send.data);
    }
  }

  useEffect(()=>{
    getHistorialMovimientos();
  },[]);


  const columnas = [
    {
      name: 'Actividad',
      cell: (row) => <p><label style={{ color: '#3f6ad8' }} ><b>{row.usuario === 'null' ? '' : row.usuario}</b></label> en <label>{row.seccion === 'null' ? '' : row.seccion},</label> <label><b className={ row.desc_2 }>{row.desc_1 === 'null' ? '' : row.desc_1}</b></label> <label>{row.fecha_hora === 'null' ? '' : row.fecha_hora}</label></p>
    }
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            HISTORIA
          </div>
        </div>
        <div className="card-body">
          <DataTable
              columns= {columnas}
              noHeader
              data={actividad}
              pagination
              paginationRowsPerPageOptions={[15]}
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla