import React, { useState } from 'react'
import MaterialTable from 'material-table';
import IconButton from "@material-ui/core/IconButton";
import { CSVLink } from "react-csv";

const Tabla = ({ compras }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const headers_compras = [
    { label: "No. Compra", key: "num_compra" },
    { label: "Descripción", key: "desc_compra" },
    { label: "Importe Total", key: "importe_total" },
    { label: "Proveedor", key: "nombre_comercial" },
    { label: "Fecha Compra", key: "fecha_compra" }
  ];

  const [columnascompras, setColumnasCompras] = useState(
    [
      { title: "No. Compra", field: "num_compra" },
      { title: "Descripción", field: "desc_compra" },
      { title: "Importe Total", field: "importe_total" },
      { title: "Proveedor", field: "nombre_comercial" },
      { title: "Fecha Compra", field: "fecha_compra" },
      {
        title: "",
        render: (row)=><button className="btn btn-primary mb-1" onClick={()=>console.log(row)} >Detalle</button>
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={compras} headers={headers_compras} filename={"Compras.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Historial Compras"
      columns={columnascompras}
      data={compras}
      onRowSelected
  />
  </div>
  )
}

export default Tabla