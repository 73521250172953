import React, { useState } from 'react'
import MaterialTable from 'material-table';
import { CSVLink } from "react-csv";

const Tabla = ({ inventario, setInventarioId, setCantidad, setNombreProducto }) =>{


  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const headers_inventario = [
    { label: "Código", key: "codigo" },
    { label: "Producto", key: "nombre_producto" },
    { label: "Cantidad", key: "cantidad_disponible" },
    { label: "Sucursal", key: "nombre_sucursal" },
    { label: "Precio Venta", key: "precio_unitario" },
    { label: "Precio Sucursal", key: "precio_sucursal" }
  ];

  const [columnasinventario, setColumnasInventario] = useState(
    [
    { title: "Código", field: "codigo" },
    { title: "Producto", field: "nombre_producto" },
    { title: "Cantidad", field: "cantidad_disponible" },
    { title: "Sucursal", field: "nombre_sucursal" },
    { title: "Precio Venta", field: "precio_unitario" },
    {
      title: "",
      render: (row)=><button onClick = {()=>actualizaDatos(row.inventario_id, row.cantidad_disponible, row.nombre_producto)} type="button" className="mt-1 btn btn-warning" data-toggle="modal" data-target="#modalpagar"  style= {{ fontSize: 15}}> <i className="fa fa-edit"></i> Editar</button>
    }
    ]
  );

  const actualizaDatos = (inventario_id, cantidad, nombre) =>{

    setInventarioId(inventario_id)
    setCantidad({
      cantidad_disponible: cantidad ? cantidad : 0
    });
    setNombreProducto(nombre)
  }

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={inventario} headers={headers_inventario} filename={"Inventario.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Inventario productos"
      columns={columnasinventario}
      data={inventario}
      onRowSelected
  />
  </div>
  )
}

export default Tabla