import React, { Fragment, useState } from 'react'
import { insertData, insertActivity } from '../helpers'
import { useBarcode } from 'react-barcodes';
import Mensaje from '../Mensaje'

const Formulario = ({categorias, unidades, getProductos, setCategoria, setProducto, producto, setImagen, imagen, actualizar, setActualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const [code, setCode] = useState('0');

  const { inputRef } = useBarcode({
    value: code,
    options: {
      background: 'white',
    }
  });


  const { 
    codigo,
    codigo_barras,
    nombre_producto,
    desc_producto,
    precio_unitario,
    img,
    unidad_medida_id,
    categoria_id,
    stock_min,
    stock_max,
    status_lote,
    status_caducidad,
    status_mayoreo,
    cant_min_mayoreo,
    precio_mayoreo,
    localizacion,
   } = producto;

  const actualizaState = e =>{
    setProducto({
      ...producto,
      [e.target.name] : e.target.value
    })
    if(e.target.name ==='categoria_id'){
      getProductos(e.target.value);
      var index = e.nativeEvent.target.selectedIndex;
      let nombre_categoria = e.nativeEvent.target[index].text
      setCategoria(nombre_categoria)
    }
    if(e.target.name ==='codigo'){
      if(e.target.value !== ''){
        setCode(e.target.value)
      }else{
        setCode('0');
      }
    }
  } 


  const seleccionarFoto = e =>{
    console.log(e.target.files[0]);
    setImagen(URL.createObjectURL(e.target.files[0]));
    setProducto({
      ...producto,
      [e.target.name] : e.target.files[0],
      img2 : e.target.files[0]
    })
    e.target.value = null;
  }

  const reiniciarForm = () =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    });
    setProducto({
      codigo: '',
      codigo_barras: '',
      nombre_producto: '',
      desc_producto: '',
      precio_unitario: '',
      img: '',
      status_precio_sucursal: 0,
      unidad_medida_id: producto.unidad_medida_id,
      categoria_id: producto.categoria_id,
      stock_min: 0,
      stock_max: 0,
      status_lote: 0,
      status_caducidad: 0,
      status_mayoreo: 0,
      cant_min_mayoreo: 0,
      precio_mayoreo: 0,
      localizacion: ''
    })
    getProductos(producto.categoria_id)
  }


  //Guardar productos
  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(categoria_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Seleccione primero una categoría',
        etiqueta: 'error'
      })
      return;
    }

    if(codigo ==='' || nombre_producto ==='' || precio_unitario === 0 || precio_unitario==='' || categoria_id === 0 || unidad_medida_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    let cant_mayoreo = 0;
    let p_mayoreo = 0;
    if (parseInt(status_mayoreo) === 1){
      if(producto.cant_min_mayoreo === '' || parseInt(producto.cant_min_mayoreo) === 0 || producto.precio_mayoreo === '' || parseInt(producto.precio_mayoreo) === 0){
        setMostrarMensaje({
          mostrar: false,
          mensaje: 'Guardando....',
          etiqueta: 'primary'
        })
        return;
      }
      cant_mayoreo = producto.cant_min_mayoreo;
      p_mayoreo = producto.precio_mayoreo;
    }

  //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));

    let formData = new FormData();
    formData.append('categoria_id', producto.categoria_id);
    formData.append('unidad_medida_id', producto.unidad_medida_id);
    formData.append('codigo', producto.codigo);
    formData.append('codigo_barras', producto.codigo_barras);
    formData.append('nombre_producto', producto.nombre_producto);
    formData.append('desc_producto', producto.desc_producto);
    formData.append('precio_unitario', producto.precio_unitario);
    formData.append('status_precio_sucursal', producto.status_precio_sucursal);
    formData.append('img', producto.img);

    formData.append('stock_min', producto.stock_min);
    formData.append('stock_max', producto.stock_max);
    formData.append('status_lote', producto.status_lote);
    formData.append('status_caducidad', producto.status_caducidad);
    formData.append('status_mayoreo', producto.status_mayoreo);
    formData.append('cant_min_mayoreo', cant_mayoreo);
    formData.append('precio_mayoreo', p_mayoreo);
    formData.append('localizacion', producto.localizacion);

    let ruta = 'productos';
    if(!actualizar){
      //Guardar
      const send = await insertData(ruta,formData);
      console.log(send)
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code === 423){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.error,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Alta producto [${producto.nombre_producto}]`, 'Productos', 'label-blue');
        reiniciarForm();
      }
    }else{
      //Actualizar
      formData.append('_method', 'PUT');
      if(producto.img2){
        formData.append('img2', producto.img);
      }
      const send = await insertData(ruta+'/'+producto.id,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Editó producto [${producto.nombre_producto}]`, 'Productos', 'label-orange');
        reiniciarForm();
      }
    }
  }


  return(
    <Fragment>
      <div className="col-md-12">
        <div className="card mb-3">
          <div className="card-header-tab card-header">
            <div className="card-header-title">
              CATEGORÍAS
            </div>
          </div>
          <div className="card-body">
              <select 
                className="form-control"
                name="categoria_id" 
                value = {categoria_id}
                onChange = {actualizaState}
                >
                <option value = "0">-- Seleccione una categoría --</option>
                {
                    categorias ?
                    categorias.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.desc_categoria}
                      </option>
                    ))
                  :null
                }
                </select>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card mb-3">
          <div className="card-header-tab card-header">
            <div className="card-header-title">
              AGREGAR PRODUCTOS
            </div>
          </div>
          <div className="card-body">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            <form className=""
              onSubmit={submitFormulario}
            > 
              <label>Código(*)</label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "codigo"
                value = {codigo}
                onChange = {actualizaState}
                />
              <label>Nombre(*)</label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "nombre_producto"
                value = {nombre_producto}
                onChange = {actualizaState}
                required
                />
              <label>Descripción</label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "desc_producto"
                value = {desc_producto && desc_producto != 'null' ? desc_producto : ''}
                onChange = {actualizaState}
                />
              <label>Precio sin IVA(*)</label>
              <input  
                type="number" 
                min="1"
                className="mb-2 form-control-sm form-control" 
                name = "precio_unitario"
                value = {precio_unitario}
                onChange = {actualizaState}
                required
                />
              <label>Unidad de medida(*)</label>
              <select 
                className="form-control"
                name="unidad_medida_id" 
                value = {unidad_medida_id}
                onChange = {actualizaState}
                required
                >
                <option value = "0">-- Seleccione una medida --</option>
                {
                    unidades ?
                    unidades.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.desc_unidad_medida}
                      </option>
                    ))
                  :null
                }
              </select>
              <label>Stock Máximo</label>
              <input  
                type="number" 
                className="mb-2 form-control-sm form-control" 
                name = "stock_max"
                value = {stock_max}
                onChange = {actualizaState}
                required
                />
              <label>Stock Minino</label>
              <input  
                type="number" 
                className="mb-2 form-control-sm form-control" 
                name = "stock_min"
                value = {stock_min}
                onChange = {actualizaState}
                required
                />
              <label>Localización <small>(Estante, Pasillos, Anaqueles, etc)</small></label>
              <input  
                type="text" 
                className="mb-2 form-control-sm form-control" 
                name = "localizacion"
                value = {localizacion}
                onChange = {actualizaState}
                />
              <label style={{ marginTop: 5 }}>Foto</label>
              <input  
                type="file" 
                className="mb-2 form-control-sm form-control" 
                name = "img"
                onChange = {seleccionarFoto}
                />
              {
                actualizar ?
                <button className="mt-1 btn btn-warning">Actualizar producto</button>
                :
                <button className="mt-1 btn btn-primary">Guardar producto</button>
              }
            </form>
          </div>
        </div>
      </div>
      <div className="col-md-6">
        <div className="card mb-3">
          <div className="card-body text-center">
          <label>Foto</label>
          <br/>
            {
              img ?
              <img style={{width:"20%", marginBottom: 10, alignSelf:'center'}} src = {imagen}></img>
              : null
            }
          </div>
        </div>
        <div className="card mb-3">
          <div className="card-body">
          <label><b>CONFIGURACIONES</b></label>
          <br/>
              <label>Manejar caducidad</label>
              <select 
                className="mb-2 form-control-sm form-control"
                name="status_caducidad" 
                value = {status_caducidad}
                onChange = {actualizaState}
                required
                >
                <option value = "0">NO</option>
                <option value = "1">SI</option>
              </select>
              <hr/>
              <label>Manejar precio mayoreo</label>
              <select 
                className="mb-2 form-control-sm form-control"
                name="status_mayoreo" 
                value = {status_mayoreo}
                onChange = {actualizaState}
                required
                >
                <option value = "0">NO</option>
                <option value = "1">SI</option>
              </select>
              {
                parseInt(status_mayoreo) === 1 ?
                <Fragment>
                  <label>Cantidad mínima mayoreo</label>
                  <input  
                    type="number" 
                    min="1"
                    className="mb-2 form-control-sm form-control" 
                    name = "cant_min_mayoreo"
                    value = {cant_min_mayoreo}
                    onChange = {actualizaState}
                    required
                    />
                  <label>Precio mayoreo sin IVA</label>
                  <input  
                    type="number" 
                    min="1"
                    className="mb-2 form-control-sm form-control" 
                    name = "precio_mayoreo"
                    value = {precio_mayoreo}
                    onChange = {actualizaState}
                    required
                    />
                    { (parseInt(status_mayoreo) === 1 && (parseInt(cant_min_mayoreo) === 0 || cant_min_mayoreo === '' || parseInt(precio_mayoreo) === 0 || precio_mayoreo === '') ) ? <Mensaje mensaje = 'Llene los campos "Cantidad Mayoreo y "Precio Mayoreo" ' etiqueta = 'error' /> : null}
                  </Fragment>
                :
                null 
              }
          </div>
        </div>
        {
        <div className="card mb-3">
          <div className="card-body text-center">
          <label>Código de barras</label>
            <br/>
            <img style={{ alignSelf: 'center', display: code === '0' ? 'none' : '' }} ref={inputRef} />
          </div>
        </div>
        }
      </div>
    </Fragment>
  )
}

export default Formulario