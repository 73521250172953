import React, { useEffect, useState, useContext } from 'react'
import { insertDataJson } from '../helpers';
import { AppContext } from '../contextApp'


const Dashboard = () => {
    const [datosDashboard, setDatosDashboard] = useState({});
    const { dominio } = useContext(AppContext);
    const getDashboard =  async () =>{
        let datos = {
            f_inicio: '',
            f_fin: '',
            sucursal_id: 0
        }
        const send = await insertDataJson('dashboard',datos);
        console.log(send)
        if(send.code === 200){
            setDatosDashboard(send.data);
        }
    }

    useEffect( async ()=>{
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        if(user){
            if(user.tipo === 'Vendedor' || user.tipo === 'Gerente' || user.tipo === 'Gerente General'){
                window.location = '/ventas';
            }
            if(user.tipo === 'Capturista'){
                window.location = '/productos';
            }
        }
        getDashboard();
    },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
          <div className="col-md-6 ">
              <div className="card mb-3 widget-content bg-midnight-bloom">
                  <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                          <div className="widget-heading">Total ventas</div>
                          <div className="widget-subheading">Durante el año vigente</div>
                      </div>
                      <div className="widget-content-right">
                          <div className="widget-numbers text-success"><span>{datosDashboard.total_ventas_todas}</span></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-md-6 ">
              <div className="card mb-3 widget-content bg-premium-dark">
                  <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                          <div className="widget-heading">Stock mínimo</div>
                          <div className="widget-subheading">Productos en stock minimo</div>
                      </div>
                      <div className="widget-content-right">
                          <div className="widget-numbers text-danger"><span>{datosDashboard.stock_min}</span></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-md-6">
              <div className="card mb-3 widget-content bg-grow-early">
                  <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                          <div className="widget-heading">Ventas</div>
                          <div className="widget-subheading">Ventas totales ($)</div>
                      </div>
                      <div className="widget-content-right">
                          <div className="widget-numbers text-white"><span>${datosDashboard.total_ventas}</span></div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-md-6">
              <div className="card mb-3 widget-content bg-arielle-smile">
                  <div className="widget-content-wrapper text-white">
                      <div className="widget-content-left">
                          <div className="widget-heading">Utilidad</div>
                          <div className="widget-subheading">Utilidad total</div>
                      </div>
                      <div className="widget-content-right">
                          <div className="widget-numbers text-white"><span>${datosDashboard.utilidad}</span></div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div className="row">
      <div className="col-md-12 col-lg-12">
              <div className="mb-3 card">
                  <div className="card-header-tab card-header">
                      <div className="card-header-title">
                          <i className="header-icon lnr-rocket icon-gradient bg-tempting-azure"> </i>
                          VENTAS
                      </div>
                  </div>
                  <div className="tab-content">
                      <div className="tab-pane fade active show" id="tab-eg-55">
                          {/* <div className="widget-chart p-3">
                              <div style={{height: "350px"}}>
                                  <canvas id="line-chart"></canvas>
                              </div>
                              <div className="widget-chart-content text-center mt-5">
                                  <div className="widget-description mt-0 text-warning">
                                      <i className="fa fa-arrow-left"></i>
                                      <span className="pl-1">175.5%</span>
                                      <span className="text-muted opacity-8 pl-1">Incremento de ventas</span>
                                  </div>
                              </div>
                          </div> */}
                          <div className="pt-2 card-body">
                              <div className="row">
                                  <div className="col-md-6">
                                      <div className="widget-content">
                                          <div className="widget-content-outer">
                                              <div className="widget-content-wrapper">
                                                  <div className="widget-content-left">
                                                      <div className="widget-numbers fsize-3 text-muted">{datosDashboard.efectivo}%</div>
                                                  </div>
                                                  <div className="widget-content-right">
                                                      <div className="text-muted opacity-6">Efectivo</div>
                                                  </div>
                                              </div>
                                              <div className="widget-progress-wrapper mt-1">
                                                  <div className="progress-bar-sm progress-bar-animated-alt progress">
                                                      <div className="progress-bar bg-danger" role="progressbar" aria-valuenow={parseFloat(datosDashboard.efectivo)} aria-valuemin="0" aria-valuemax="100" style={{width: parseFloat(datosDashboard.efectivo)+'%'}}></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <div className="widget-content">
                                          <div className="widget-content-outer">
                                              <div className="widget-content-wrapper">
                                                  <div className="widget-content-left">
                                                      <div className="widget-numbers fsize-3 text-muted">{datosDashboard.tarjeta}%</div>
                                                  </div>
                                                  <div className="widget-content-right">
                                                      <div className="text-muted opacity-6">Tarjeta</div>
                                                  </div>
                                              </div>
                                              <div className="widget-progress-wrapper mt-1">
                                                  <div className="progress-bar-sm progress-bar-animated-alt progress">
                                                      <div className="progress-bar bg-success" role="progressbar" aria-valuenow={parseFloat(datosDashboard.tarjeta)} aria-valuemin="0" aria-valuemax="100" style={{width: parseFloat(datosDashboard.tarjeta)+'%'}}></div>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="col-md-12 col-lg-12">
              <div className="mb-3 card">
                  <div className="card-body">
                      <div className="tab-content">
                          <div className="tab-pane fade show active" id="tabs-eg-77">
                              <h6 className="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">Top Productos</h6>
                              <div className="scroll-area-sm">
                                  <div className="scrollbar-container">
                                      <ul className="rm-list-borders rm-list-borders-scroll list-group list-group-flush">

                                          {
                                              datosDashboard.mas_vendidos ?
                                              datosDashboard.mas_vendidos.map(item=>(
                                                <li className="list-group-item">
                                                    <div className="widget-content p-0">
                                                        <div className="widget-content-wrapper">
                                                            <div className="widget-content-left mr-3">
                                                            <img width="42" className="rounded-circle" src={ item.img ? dominio+'/'+item.img : 'assets/icon_producto.png' } alt=""/>
                                                            </div>
                                                            <div className="widget-content-left">
                                                                <div className="widget-heading">{item.descripcion}</div>
                                                                <div className="widget-subheading">{item.desc_producto}</div>
                                                            </div>
                                                            <div className="widget-content-right">
                                                                <div className="font-size-xlg text-muted">
                                                                    <small className="opacity-5 pr-1">$</small>
                                                                    <span>{item.importe}</span>
                                                                    <small className="text-success pl-2">
                                                                        <i className="fa fa-angle-up"></i>
                                                                    </small>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                              ))
                                            : null
                                          }
                                      </ul>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  )
}

export default Dashboard