import React, { useState, useEffect } from 'react'
import { getData, insertActivity } from '../helpers'
import Tabla from './Tabla'

const ReporteInventario = () =>{
  const [transferencia, setTransferencia] = useState([]);

  const getTransferencias =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    const send = await getData('historial-transferencia/'+user.empresa_id);
    console.log(send)
    if(send.code === 200){
      setTransferencia(send.data);
    }
    insertActivity(user.name, `Consultó transferencias`, 'Historial transferencias', 'label-blue');
  }

  useEffect(()=>{
    getTransferencias();
  },[]);
  return(
    <div className="app-main__inner">           
      <div className="row">
        <Tabla 
          transferencia = {transferencia}
        />
      </div>
    </div>
  )
}

export default ReporteInventario