import React, { useEffect, useState } from 'react'
import { getData, deleteItem, insertActivity } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'


const ContainerUnidadMedida = () =>{  
  const [clientes, setClientes]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [cliente, setCliente] = useState({
    codigo: '',
    nombre_comercial: '',
    nombre_razon: '',
    rfc: '',
    calle: '',
    num_ext: '',
    num_int: '',
    colonia: '',
    cp: '',
    municipio: '',
    estado: '',
    pais: '',
    telefono: '',
    correo: '',
    permitir_credito: '',
    saldo_actual: '',
    descuento: ''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });


  const getClientes =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('clientes');
    console.log(send)
    setClientes(send.data);
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setCliente(data);
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('clientes/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado el cliente',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getClientes();
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó cliente [${send.data.nombre_comercial}]`, 'Cliente', 'label-red');
    }
  }

  useEffect(()=>{
    getClientes();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getClientes = {getClientes}
          setCliente = {setCliente}
          cliente = {cliente}

          setActualizar = {setActualizar}
          actualizar = {actualizar}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          clientes = {clientes}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default ContainerUnidadMedida