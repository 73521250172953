import React, { useState } from 'react'
import MaterialTable from 'material-table';
import IconButton from "@material-ui/core/IconButton";
import { CSVLink } from "react-csv";

const Tabla = ({ compras }) =>{

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const headers_compras = [
    { label: "Folio General", key: "id" },
    { label: "No. Venta", key: "id" },
    { label: "Cliente", key: "nombre_comercial" },
    { label: "Sucursal", key: "nombre_sucursal" },
    { label: "Subtotal", key: "subtotal" },
    { label: "Vendedor", key: "name" },
    { label: "Fecha Venta", key: "fecha_venta" }
  ];

  const [columnascompras, setColumnasCompras] = useState(
    [
      { title: "Folio General", field: "id" },
      { title: "No. Venta", field: "folio" },
      { title: "Cliente", field: "nombre_comercial" },
      { title: "Sucursal", field: "nombre_sucursal" },
      { title: "Subtotal", field: "subtotal" },
      { title: "Vendedor", field: "name" },
      { title: "Fecha Venta", field: "fecha_venta" },
      {
        title: "",
        render: (row)=><div>
                        {/* <button className="btn btn-primary mb-1" onClick={()=>console.log(row)} >Detalle</button> */}
                        <button className="btn btn-warning mb-1" onClick={()=>window.open('/ticket?id='+row.id, '_blank')} ><li className="fa fa-print" style={{ fontSize: 20 }} ></li></button>
                      </div>
      }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={compras} headers={headers_compras} filename={"Ventas.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Historial Ventas"
      columns={columnascompras}
      data={compras}
      onRowSelected
  />
  </div>
  )
}

export default Tabla