import React, { useEffect, useState } from 'react';
import { getCookie } from '../components/Login/AuthenticatedComponent'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Header from './Template/Header'
import Sidebar from './Template/Sidebar'
import Footer from './Template/Footer'

import Dashboard from './Dashboard/Dashboard'

import ContainerVentas from './Ventas/ContainerVentas'
import ContainerHistorialVenta from './HistorialVenta/ContainerHistorialVenta'
import ContainerCompras from './Compras/ContainerCompras'
import ContainerHistorialCompra from './HistorialCompra/ContainerHistorialCompra'

import ContainerProductos from './Productos/ContainerProductos'
import ListaProductos from './Productos/ListaProductos'
import ContainerTransferir from './Transferir/ContainerTransferir'
import ContainerHistorialTransferencia from './HistorialTransferencia/ContainerHistorialTransferencia'

import ContainerReporteInventario from './Inventario/ContainerReporteInventario'

import ContainerCategorias from './Categorias/ContainerCategorias'
import ContainerUnidadMedida from './UnidadMedida/ContainerUnidadMedida'
import ContainerClientes from './Clientes/ContainerClientes'
import ContainerProveedores from './Proveedores/ContainerProveedores'
import ContainerUsuarios from './Usuarios/ContainerUsuarios'

import ContainerSucursales from './Sucursales/ContainerSucursales'

import ContainerHistorialMovimiento from './HistorialMovimiento/ContainerHistorialMovimiento'



const Main = (props) => {
  const [active, setActive] = useState(0);
  const [despliega, setDespliega] = useState(0);

// const validar = async () =>{
//   const isAuthenticated = await getCookie('STORAGE_KEY');
//   if(!isAuthenticated){
//     props.history.push("/login");
//   }
// }

  useEffect(() => { 

//    validar();

    if(window.location.pathname==="/dashboard"){
      setActive(0)
      setDespliega(0)
    }else if(window.location.pathname==="/ventas"){
      setActive(1)
      setDespliega(1)
    }else if(window.location.pathname==="/historial-ventas"){
      setActive(16)
      setDespliega(1)
    }else if(window.location.pathname==="/productos"){
      setActive(2)
      setDespliega(2)
    }else if(window.location.pathname==="/lista-productos"){
        setActive(17)
        setDespliega(2)
    }else if(window.location.pathname==="/transferir"){
      setActive(3)
      setDespliega(2)
    }else if(window.location.pathname==="/historial-transferencia"){
      setActive(4)
      setDespliega(2)
    }else if(window.location.pathname==="/compras"){
        setActive(5)
        setDespliega(3)
    }else if(window.location.pathname==="/historial-compras"){
        setActive(6)
        setDespliega(3)
    }else if(window.location.pathname==="/unidad-medida"){
      setActive(7)
      setDespliega(4)
    }else if(window.location.pathname==="/categorias"){
      setActive(8)
      setDespliega(4)
    }else if(window.location.pathname==="/clientes"){
      setActive(9)
      setDespliega(4)
    }else if(window.location.pathname==="/proveedores"){
      setActive(10)
      setDespliega(4)
    }else if(window.location.pathname==="/reporte-inventario"){
      setActive(11)
      setDespliega(5)
    }else if(window.location.pathname==="/sucursales"){
      setActive(13)
    }else if(window.location.pathname==="/usuarios"){
      setActive(14)
    }else if(window.location.pathname==="/actividad"){
      setActive(15)
    }
}, [])


  return (
    <Router>
      <div className="App">
        <div className="app-container app-theme-white body-tabs-shadow fixed-sidebar fixed-header">
            <Header />
            <Sidebar 
              active = {active}
              despliega = {despliega}
            />
            <div className="app-main"> 
                <div className="app-main__outer">
                    <Switch>
                      <Route path="/actividad" component={ContainerHistorialMovimiento} />
                      <Route path="/sucursales" component={ContainerSucursales} />
                      <Route path="/usuarios" component={ContainerUsuarios} />
                      <Route path="/productos" component={ContainerProductos} />
                      <Route path="/lista-productos" component={ListaProductos} />
                      <Route path="/transferir" component={ContainerTransferir} />
                      <Route path="/historial-transferencia" component={ContainerHistorialTransferencia} />
                      <Route path="/reporte-inventario" component={ContainerReporteInventario} />
                      <Route path="/ventas" component={ContainerVentas} />
                      <Route path="/historial-ventas" component={ContainerHistorialVenta} />
                      <Route path="/compras" component={ContainerCompras} />
                      <Route path="/historial-compras" component={ContainerHistorialCompra} />
                      <Route path="/proveedores" component={ContainerProveedores} />
                      <Route path="/clientes" component={ContainerClientes} />
                      <Route path="/unidad-medida" component={ContainerUnidadMedida} />
                      <Route path="/categorias" component={ContainerCategorias} />
                      <Route path="/home" component={Dashboard} />
                      <Route path="/" component={Dashboard} />
                    </Switch>
                  <Footer />
                </div>
            </div>
        </div>
      </div>
    </Router>
  );
}

export default Main;
