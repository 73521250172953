import React, { useEffect, useState, useContext } from 'react'
import { getData, insertData, deleteItem, insertActivity } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import Mensaje from '../Mensaje'

const ContainerUsuarios = () =>{  
  const [usuarios, setUsuarios]= useState([]);
  const [sucursales, setScursales]= useState([]);
  const [usuario, setUsuario] = useState({
    name: '',
    email: '',
    password: '',
    password_confirmation: '',
    status: 1,
    tipo: 'Vendedor',
    empresa_id: 1
  });
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getUsuarios =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('CLUBFOODIE_USER'));
    const send = await getData('usuario-empresa');
    console.log(send)
    setUsuarios(send.data);
  }

  const getSucursales =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('sucursales');
    console.log(send)
    setScursales(send.data);
  }

  const actualiza = async (data, status) =>{
    let formData = new FormData();
    formData.append('status',status);
    formData.append('_method', 'PUT');
    const send = await insertData('usuario-empresa/'+data.id,formData);
    console.log(send);
    if(send.status ==='error'){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.message,
        etiqueta: 'error'
      })
      return;
    }
    if(send.code===200){
      getUsuarios();
    }
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('usuario-empresa/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'danger'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado el usuario',
        etiqueta: 'danger'
      })
      getUsuarios();
      setBotonEliminar(false);
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó un usuario`, 'Usuarios', 'label-red');
    }
  }

  useEffect(()=>{
    getUsuarios();
    getSucursales();

  },[]);

  return(
    <div class="app-main__inner">           
      <div class="row">
        <Formulario 
          setUsuarios = {setUsuarios}
          setUsuario = {setUsuario}
          usuario = {usuario}
          getUsuarios = {getUsuarios}
          sucursales = {sucursales}

        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}
          usuarios = {usuarios}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}

          sucursales = {sucursales}
        />
      </div>
    </div>
  )
}

export default ContainerUsuarios