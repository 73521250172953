import React, { useContext, useState } from 'react';
import MaterialTable from 'material-table';
import { AppContext } from '../contextApp'


const Tabla = ()=> {

  const { historialinventario,  setHistorialInventario, compra, setCompra } = useContext(AppContext);

  
  const [columnasdetallecompras, setColumnasDetalleCompras] = useState(
    [
      { title: 'Producto', field: 'nombre_producto' },
      { title: 'Sucursal', field: 'nombre_sucursal' },
      { title: 'Cantidad', field: 'cantidad' }
    ]
  );

  return (
      <div className="col-md-12">
        <MaterialTable
          options={{
            headerStyle:{fontSize:'12px'},
            bodyStyle:{fontSize:'12px'},
            pageSize: 10
          }}
          title="Productos/Compras"
          columns={columnasdetallecompras}
          data={historialinventario}
          onRowSelected
          editable={{
              onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...historialinventario];
                      const index = oldData.tableData.id;
                      dataUpdate[index] = newData;
                      setHistorialInventario([...dataUpdate]);
                      setCompra({
                        ...compra,
                        historial_inventario: dataUpdate
                      });
                      resolve();
                    }, 1000)
                  }),
              onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...historialinventario];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setHistorialInventario([...dataDelete]);
                      setCompra({
                        ...compra,
                        historial_inventario: dataDelete
                      });
                      resolve()
                    }, 1000)
                  })
          }}
      />
      </div>
  );
}

export default Tabla
