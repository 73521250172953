import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

import Container from './Container'
import ContainerLogin from './Login/ContainerLogin'
import ListaSucursales from './Sucursales/ListaSucursales'

import Ticket from './Ticket/PrevioTicket'
import CodigoBarras from './Productos/CodigoBarras'

const Main = () => {
  return (
    <Router>
      <main>
          <Switch>
            <Route path="/ticket"  component={Ticket} />
            <Route path="/codigo-barras"  component={CodigoBarras} />
            <Route path="/login"  component={ContainerLogin} />
            <Route path="/lista-sucursales"  component={ListaSucursales} />
            <Route path="/"  component={Container} />
          </Switch>
      </main>
    </Router>

  );
}

export default Main;
