import React, { Fragment, useState, useEffect} from 'react';
import { login } from '../helpers'
import Mensaje from '../Mensaje'
import { setCookie } from './AuthenticatedComponent'


const ContainerLogin=props=>{
  const [user, actualizaUser] = useState({
      email:'',
      password: ''
  })

  //Extraer valores
  const {email, password} = user;

  const [mensaje, actualizaMensaje] = useState({
    status:false,
    message:'',
    label:''
  });

  const {message, label} = mensaje;

  const cerrarSesion= async()=>{
    //const cerrar =  await logout();
    document.cookie = "STORAGE_KEY=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    localStorage.removeItem('STOCK_USERTOKEN');
    localStorage.removeItem('STOCK_USER');
    localStorage.removeItem('STOCK_SUCURSAL');
  }

  useEffect(()=>{
    cerrarSesion();
  },[]);

  const actualizaState = e =>{
    actualizaUser({
      ...user,
      [e.target.name] : e.target.value
    })
  }

  const submitLogin = async e =>{
    e.preventDefault();
    
    const sendUser = await login('login',user);
    console.log(sendUser);
    if(sendUser.user){
      localStorage.setItem('STOCK_USERTOKEN',sendUser.access_token);
      const user = JSON.stringify(sendUser.user);
      localStorage.setItem('STOCK_USER',user);
      actualizaMensaje({
        status:true,
        label: "primary",
        message: 'Iniciando....'
      })
      setCookie('STORAGE_KEY', sendUser.access_token);
      if(sendUser.user.admin === 'true'){
        window.location = '/home';
        return;
      }else{
        window.location = '/ventas';
        return;
      }
    }

    actualizaMensaje({
      status:true,
      label: "error",
      message: sendUser.message
    })

    //window.location = '/login';
  }

  return(
    <Fragment>
      <div className="row justify-content-center" style={{backgroundImage: `url('/assets/fondo_login.jpg')`, backgroundSize: 'cover', height: '100vh' }}>
          <div className="col-md-8">
              <div className="card">
                  <div className="card-header">Inicio de Sesión</div>

                  <div className="card-body">
                      <form
                        onSubmit={submitLogin}
                      >
                          <div className="form-group row">
                            <div className="col-md-2 text-center container_logo_login">
                              <div className ="logo_login">
                                <img src="assets/logo_adaptingstock.png" width="180" />
                              </div>
                            </div>
                            <div className="col-md-10">
                              <div className="form-group row">
                                <div className="col-md-12">
                                  {mensaje.status ? <Mensaje etiqueta={label} mensaje={message} />: null}
                                </div>
                                <label htmlFor="usuario" className="col-md-4 col-form-label text-md-right">Usuario:</label>
                                <div className="col-md-8">
                                    <input id="email" type="email" className="form-control col-md-12" name="email" value={email} onChange={actualizaState}  required/>
                                </div>
                              </div>
                              <div className="form-group row">
                                <label htmlFor="password" className="col-md-4 col-form-label text-md-right">Contraseña:</label>
                                <div className="col-md-8">
                                    <input id="password" type="password" className="form-control col-md-12" name="password" value={password} onChange={actualizaState}  required  />
                                </div>
                              </div>
                              <div className="form-group row mb-0">
                                <div className="col-md-8 offset-md-4">
                                    <button type="submit" className="btn btn-primary col-md-12">
                                        Iniciar Sesión
                                    </button>
                                </div>
                              </div>
                            </div>
                          </div>
                      </form>
                  </div>
              </div>
          </div>
      </div>
    </Fragment>
  )
}

export default ContainerLogin

