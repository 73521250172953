import React, { useState } from 'react'
import { AppContext } from '../contextApp'

const Modal =({ actualizar, cantidad, setCantidad, nombreproducto })=>{

   const actualizaState = e =>{
    setCantidad({
      ...cantidad,
      [e.target.name] : e.target.value
    })
  }

  return(
    <div className="modal fade" style={{ marginTop: 80, marginLeft: 100 }} id="modalpagar" role="dialog" aria-labelledby="mediumModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-xs" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="mediumModalLabel">Actualizar [{nombreproducto}]</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div className="card-body card-block">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                  <label>Nueva Cantidad:</label>
                    <input  
                    type="number" 
                    className="mb-2 form-control-sm form-control" 
                    name = "cantidad_disponible"
                    value = {cantidad.cantidad_disponible}
                    onChange = {actualizaState}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              <button className="mt-1 btn btn-success" data-dismiss="modal" onClick={()=>actualizar()} style= {{ fontSize: 15, width: 200}}> <i className="fa fa-check-circle"></i> Actualizar Cantidad</button>
            </div>
        </div>
      </div>
    </div>
  )
}

export default Modal