import React, { useState } from 'react'
import MaterialTable from 'material-table';
import { CSVLink } from "react-csv";

const Tabla = ({ inventario }) =>{


  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',
  }

  const headers_inventario = [
    { label: "Código", key: "codigo" },
    { label: "Producto", key: "nombre_producto" },
    { label: "Descripción", key: "desc_producto" },
    { label: "Categoría", key: "desc_categoria" },
    { label: "Precio Venta", key: "precio_unitario" }
  ];

  const [columnasinventario, setColumnasInventario] = useState(
    [
    { title: "Código", field: "codigo" },
    { title: "Producto", field: "nombre_producto" },
    { title: "Descripción", field: "desc_producto" },
    { title: "Categoría", field: "desc_categoria" },
    { title: "Precio Venta", field: "precio_unitario" },
    {
      title: "",
      render: (row)=><button className="btn btn-primary mb-1" onClick={()=>{
        window.open('/codigo-barras?codigo='+row.codigo, '_blank');
      }} ><li style={{ fontSize: 20 }} className="fa fa-barcode"></li> Imprimir</button>
    }
    ]
  );

  return(
    <div className="col-md-12">
      <div className="offset-md-10 col-md-2 text-right">
        <button style={{marginLeft: 5}} type="button" className="btn btn-success mb-1" >
            <li className="fa fa-cloud-download"></li> <CSVLink data={inventario} headers={headers_inventario} filename={"Productos.csv"} style={{color: 'white', width: '10%'}}>Excel</CSVLink>
          </button> 
      </div>
    <MaterialTable
      options={{
        headerStyle:{fontSize:'12px'},
        bodyStyle:{fontSize:'12px'},
        pageSize: 10
      }}
      title="Lista de productos"
      columns={columnasinventario}
      data={inventario}
      onRowSelected
    />
  </div>
  )
}

export default Tabla