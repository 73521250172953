import React from 'react'
import DataTable from 'react-data-table-component'
import Mensaje from '../Mensaje'

const Tabla = ({clientes, actualiza, mostrarmensaje2, eliminaItem, botoneliminar, setBotonEliminar }) =>{

  const columnas = [
    {
      name: 'Código',
      cell: (row) => <p>{row.codigo === 'null' ? '' : row.codigo}</p>
    },
    {
      name: 'Nombre',
      selector : 'nombre_comercial',
    },
    {
      name: 'Teléfono',
      cell: (row) => <p>{row.telefono === 'null' ? '' : row.telefono}</p>
    },
    {
      name: 'Correo',
      cell: (row) => <p>{row.correo === 'null' ? '' : row.correo}</p>
    },
    {
      name: '',
      cell: (row) => <div>
                        <button onClick={()=>actualiza(row)} type="button" className="btn btn-outline-warning btn-sm" data-tip="Editar">
                          <li className="fa fa-pencil"></li> 
                        </button>
                        <button onClick={()=>setBotonEliminar(true)} type="button" className="btn btn-outline-danger btn-sm" data-tip="Cancelar" data-toggle="modal" data-target="#mediumModalEliminaCotizacion">
                          <li className="fa fa-trash"></li>
                        </button>
                        {
                          botoneliminar ?
                          <button onClick={()=>eliminaItem(row)} type="button" className="btn btn-outline-danger btn-sm"  >
                            Confirmar
                          </button>
                          : null
                        }
                    </div>,
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },  
  ]

  const paginacionOpciones={
    rowsPerPageText : 'Filas por paginas',
    rangeSeparatorText: 'de',
    selectAllRowsItem: true,
    selectAllRowsItemText: 'Todos',

  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            LISTA DE CLIENTES
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje2.mostrar ? <Mensaje mensaje = {mostrarmensaje2.mensaje} etiqueta = {mostrarmensaje2.etiqueta} /> : null}
          <DataTable
              columns= {columnas}
              noHeader
              data={clientes}
              pagination
              paginationComponentOptions = {paginacionOpciones}
              fixedHeader
              fixedHeaderScrollHeight='600px'
            />
        </div>
      </div>
    </div>
  )
}

export default Tabla