import React, { Fragment, useState } from 'react'
import { insertData, insertActivity } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getClientes, setCliente, cliente, setActualizar, actualizar}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const { 
    codigo,
    nombre_comercial,
    nombre_razon,
    rfc,
    calle,
    num_ext,
    num_int,
    colonia,
    cp,
    municipio,
    estado,
    pais,
    telefono,
    correo,
    permitir_credito,
    saldo_actual,
    descuento
  } = cliente;

  const actualizaState = e =>{
    setCliente({
      ...cliente,
      [e.target.name] : e.target.value
    })
  } 


  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setCliente({
      codigo: '',
      nombre_comercial: '',
      nombre_razon: '',
      rfc: '',
      calle: '',
      num_ext: '',
      num_int: '',
      colonia: '',
      cp: '',
      municipio: '',
      estado: '',
      pais: '',
      telefono: '',
      correo: '',
      permitir_credito: '',
      saldo_actual: '',
      descuento: ''
    })
    getClientes();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(nombre_comercial ===''){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }


    let formData = new FormData();
    formData.append('codigo', cliente.codigo);
    formData.append('nombre_comercial', cliente.nombre_comercial);
    formData.append('nombre_razon', cliente.nombre_razon);
    formData.append('rfc', cliente.rfc);
    formData.append('calle', cliente.calle);
    formData.append('num_ext', cliente.num_ext);
    formData.append('num_int', cliente.num_int);
    formData.append('colonia', cliente.colonia);
    formData.append('cp', cliente.cp);
    formData.append('municipio', cliente.municipio);
    formData.append('estado', cliente.estado);
    formData.append('pais', cliente.pais);
    formData.append('telefono', cliente.telefono);
    formData.append('correo', cliente.correo);

    let ruta = 'clientes';
    if(!actualizar){
      //Guardar
      const send = await insertData(ruta,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Alta cliente [${cliente.nombre_comercial}]`, 'Cliente', 'label-blue');
        reiniciarForm();
      }
    }else{
      //Actualizar
      formData.append('_method', 'PUT');
      const send = await insertData(ruta+'/'+cliente.id,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===200){
        setActualizar(false);
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Editó cliente [${cliente.nombre_comercial}]`, 'Cliente', 'label-orange');
        reiniciarForm();
      }
    }

  }

  return(
  <Fragment>
    <div className="col-md-6">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            CLIENTES
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Código(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "codigo"
              value = {codigo}
              onChange = {actualizaState}
              required
              />
            <label>Nombre comercial(*)</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "nombre_comercial"
              value = {nombre_comercial}
              onChange = {actualizaState}
              required
              />
            <label>Teléfono</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "telefono"
              value = {telefono}
              onChange = {actualizaState}
              />
            <label>Correo</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "correo"
              value = {correo}
              onChange = {actualizaState}
              />
            <label>Razón social</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "nombre_razon"
              value = {nombre_razon}
              onChange = {actualizaState}
              />
            <label>RFC</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "rfc"
              value = {rfc}
              onChange = {actualizaState}
              />
            <label>Calle</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "num_ext"
              value = {num_ext}
              onChange = {actualizaState}
              />

              {
                actualizar ?
                <button className="mt-1 btn btn-warning">Actualizar cliente</button>
                :
                <button className="mt-1 btn btn-primary">Guardar cliente</button>
              }
          </form>
        </div>
      </div>
    </div>
    <div className="col-md-6">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
        </div>
        <div className="card-body">
          <form className=""
            onSubmit={submitFormulario}
          > 
            <label>Número exterior</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "num_int"
              value = {num_int}
              onChange = {actualizaState}
              />
            <label>Número interior</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "calle"
              value = {calle}
              onChange = {actualizaState}
              />
            <label>Colonia</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "colonia"
              value = {colonia}
              onChange = {actualizaState}
              />
            <label>C.P.</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "cp"
              value = {cp}
              onChange = {actualizaState}
              />
            <label>Municipio</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "municipio"
              value = {municipio}
              onChange = {actualizaState}
              />
            <label>Estado</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "estado"
              value = {estado}
              onChange = {actualizaState}
              />
            <label>Pais</label>
            <input  
              type="text" 
              className="mb-2 form-control-sm form-control" 
              name = "pais"
              value = {pais}
              onChange = {actualizaState}
              />
          </form>
        </div>
      </div>
    </div>
  </Fragment>
  )
}

export default Formulario