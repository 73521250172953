import React, { useContext } from 'react'
import { AppContext } from '../contextApp'

const Modal =()=>{
  const { 
    venta,
    setVenta
   } = useContext(AppContext);


   const actualizaState = e =>{
    setVenta({
      ...venta,
      [e.target.name] : e.target.value
    })
  }


  return(
    <div className="modal fade" style={{ marginTop: 80, marginLeft: 100 }} id="modalpagar" role="dialog" aria-labelledby="mediumModalLabel" aria-hidden="true">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="mediumModalLabel">Pagar</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
              <div className="card-body card-block">
                <div className="row">
                  <div className="col-md-6 offset-md-3">
                    <label>Tipo de pago:</label>
                    <select 
                      className="form-control"
                      name = "tipo_pago"
                      onChange = {actualizaState}
                      value = {venta.tipo_pago}
                      >
                      <option value="Efectivo">Efectivo</option>
                      <option value="Tarjeta">Tarjeta</option>
                    </select>
                  </div>
                  <div className="col-md-6 offset-md-3 mt-4">
                    <label><b>Total:</b></label>
                    <label><b style={{ fontSize: 20 }}> ${venta.subtotal}</b></label>
                  </div>
                  {
                    venta.tipo_pago === "Efectivo" ?
                    <div className="col-md-6 offset-md-3">
                    <label>Con cuanto paga:</label>
                      <input  
                      style= {
                        parseFloat(venta.cuanto_paga) < parseFloat(venta.subtotal) ?
                        { borderWidth: 1, borderColor: 'red' }
                        : null
                      }
                      type="number" 
                      className="mb-2 form-control-sm form-control" 
                      name = "cuanto_paga"
                      value = {venta.cuanto_paga}
                      onChange = {actualizaState}
                      />
                  </div>
                    : null
                  }
                  {
                    venta.tipo_pago === "Efectivo" ?
                      <div className="col-md-6 offset-md-3">
                      <label style={{ fontSize: 15 }} >Cambio: <b> $ {
                        parseFloat(venta.cuanto_paga) > parseFloat(venta.subtotal) ?
                          parseFloat(venta.cuanto_paga) - parseFloat(venta.subtotal)
                          : null
                      }</b></label>
                      </div>
                    :null
                  }
                </div>
              </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
                {
                  parseFloat(venta.cuanto_paga) >= parseFloat(venta.subtotal) && venta.subtotal > 0 ?
                    <button className="mt-1 btn btn-success" style= {{ fontSize: 15, width: 200}}> <i className="fa fa-check-circle"></i> Pagar</button>
                    :
                    <button disabled className="mt-1 btn btn-success" style= {{ fontSize: 15, width: 200}}> <i className="fa fa-check-circle"></i> Pagar</button>
                }
            </div>
        </div>
      </div>
    </div>
  )
}

export default Modal