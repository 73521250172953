import React, { useState, useContext, useEffect } from 'react';
import Select from 'react-select';
import { calcularTotal } from '../helpers';
import { AppContext } from '../contextApp'
import Mensaje from '../Mensaje'

const Formulario = ({productos, preciosucursales}) =>{
  const { 
    venta, 
    setVenta,
    detalleventa,
    setDetalleVenta
   } = useContext(AppContext);
   

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etiqueta: ''
  });

  const [producto, setProducto] = useState({
    producto_id: 0,
    cantidad: 0,
    precio_unitario: 0,
  })

  function seleccionarProducto(detalle_producto) {
    setProducto({
      ...producto,
      producto_id: detalle_producto.value,
      precio_unitario: detalle_producto.precio_lista
    })
  }


  const actualizaState = e =>{
    setProducto({
      ...producto,
      [e.target.name] : e.target.value
    })
  }

  useEffect(()=>{
    calcularTotal(venta, setVenta, detalleventa);
  },[detalleventa]);


// Agregar a tabla
  const submitFormulario = async e =>{
    setMostrarMensaje({
      mostrar: false,
      mensaje: '',
      etiqueta: ''
    });

    e.preventDefault();

    if (detalleventa.producto_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un producto',
        etiqueta: 'error'
      });
      return;
    }

    let existe_producto;
    if(detalleventa.length > 0){
      existe_producto = detalleventa.filter(product => product.producto_id === producto.producto_id);
      console.log(existe_producto)
      if(existe_producto.length > 0){
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Ya se ha agragado este producto. Modifique la cantidad',
          etiqueta: 'error'
        });
        return;
      }
    }
    const detalle_producto = productos.filter(product => product.value === producto.producto_id)[0];
    let precio_producto;
    if(preciosucursales.length > 0){
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      precio_producto = preciosucursales.filter(product => product.producto_id === producto.producto_id && product.sucursal_id === user.empresa_id)[0];
    }
    console.log(precio_producto);
    console.log('Guardando detalle....');

    const precio_lista = precio_producto ? precio_producto.precio_unitario : detalle_producto.precio_lista;
    const importe = precio_lista * parseInt(producto.cantidad);
    const precio_iva = importe * 0.16;
    const total = importe* 1.16;
    const nuevo_producto = {
      partida: 1,
      codigo: detalle_producto.codigo,
      cantidad: producto.cantidad,
      descripcion: detalle_producto.descripcion,
      precio_lista: precio_lista,
      descuento: 0,
      pu_final: precio_lista,
      importe_partida: (importe).toFixed(2),
      iva_partida: (precio_iva).toFixed(2),
      total_partida: (total).toFixed(2),
      utilidad: 0,
      utilidad_descuento: 0,
      producto_id: detalle_producto.value
    }
    console.log(nuevo_producto)
    // var lista_productos = [];
    // detalleventa.map(item=>{
    //   lista_productos.push(item);
    // })
    // lista_productos.push(nuevo_producto);

    await setDetalleVenta([
      ...detalleventa,
        nuevo_producto
    ]);

    // const x = await setVenta({
    //   ...venta,
    //   detalle_venta:[
    //     ...venta.detalle_venta,
    //       nuevo_producto
    //   ]
    // });

    //calcularTotal(venta, setVenta, detalleventa);
  }


  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            PRODUCTO
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <div className = "row">
              <div className="col-md-7">
                <label>Producto(*)</label>
                <Select 
                  options={productos} 
                  onChange={seleccionarProducto}
                />
              </div>
              <div className="col-md-2">
                <label>Cantidad</label>
                <input  
                  type="number" 
                  className="mb-2 form-control-sm form-control" 
                  name = "cantidad"
                  value = {producto.cantidad}
                  onChange = {actualizaState}
                  required
                  min="1" 
                  pattern="^[0-9]+"
                  />
              </div>
              <div className="col-md-3 text-left mt-3">
                <button className="mt-1 btn btn-primary"><li className="fa fa-arrow-down"></li> Agregar producto</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario