import React, { useEffect, useState, useContext } from 'react'
import { getData, deleteItem, insertActivity, buscarTablasConsultas } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const ContainerProductos = () =>{  
  const { dominio } = useContext(AppContext);
  const [categorias, setCategorias]= useState([]);
  const [unidades, setUnidades]= useState([]);
  const [productos, setProductos]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [producto, setProducto]= useState({
    codigo: '',
    codigo_barras: '',
    nombre_producto: '',
    desc_producto: '',
    precio_unitario: '',
    img: '',
    status_precio_sucursal: 0,
    unidad_medida_id: 0,
    categoria_id: 0,
    stock_min: 0,
    stock_max: 0,
    status_lote: 1,
    status_caducidad: 0,
    status_mayoreo: 0,
    cant_min_mayoreo: 0,
    precio_mayoreo: 0,
    localizacion: ''

  });

  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [categoria, setCategoria]= useState('');
  const [imagen, setImagen]= useState(null);

  const getCategorias =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('categorias');
    console.log(send)
    if(send.code===200){
      setCategorias(send.data);
    }
  }

  const getUnidades =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('unidad-medida');
    console.log(send)
    if(send.code===200){
      setUnidades(send.data);
    }
  }

  const getProductos =  async (id) =>{
    console.log(id)
    setProductos([]);
    const send = await getData('productos-categoria/'+id);
    console.log(send)
    if(send.code===200){
      setProductos(send.data);
      const datos = await buscarTablasConsultas(send.data,'','codigo','nombre_producto','no_pedido');
      actualizaListaBusqueda(datos);
    }
  }


  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setProducto(data);
    setImagen(dominio+'/'+data.img)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('productos/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado el producto',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getProductos(producto.categoria_id);
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó producto [${send.data.nombre_producto}]`, 'Productos', 'label-red');
    }
  }

  //BUSQUEDA
  const [campobusca, actualizaCampo] = useState('');
  const [listaBusqueda, actualizaListaBusqueda] = useState([]);
  const actualizaStateBusqueda = async e =>{
    e.persist();
    await actualizaCampo(
      e.target.value
    )
    const datos = await buscarTablasConsultas(productos,e.target.value,'codigo','nombre_producto','no_pedido');
    actualizaListaBusqueda(datos);
  }


  useEffect(()=>{
    getCategorias();
    getUnidades();
  },[]);

  return(
    <div className="app-main__inner">           
      <div class="row">
        <Formulario 
          categorias = {categorias}
          unidades = {unidades}
          getProductos = {getProductos}
          setCategoria = {setCategoria}

          setActualizar = {setActualizar}
          actualizar = {actualizar}

          setProducto = {setProducto}
          producto = {producto}

          setImagen = {setImagen}
          imagen = {imagen}

        />
      </div>
      <div className="row">
      <Tabla 
          listaBusqueda = {listaBusqueda}
          categoria = {categoria}
          actualiza = {actualiza}

          mostrarmensaje2 = {mostrarmensaje2}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}

          actualizaStateBusqueda = {actualizaStateBusqueda}
          campobusca = {campobusca}
        />
      </div>
    </div>
  )
}

export default ContainerProductos