import React, { useState, useContext } from 'react';
import Select from 'react-select';
import { insertDataJson, insertActivity } from '../helpers';
import { AppContext } from '../contextApp'
import Mensaje from '../Mensaje'

const Formulario = ({proveedores}) =>{
  const { 
    compra, 
    setCompra,
    setHistorialInventario
   } = useContext(AppContext);
   
  const { 
    fecha_compra,
    num_compra,
    desc_compra,
    importe_total,
    proveedor_id,
    detalle_compra,
    historial_inventario
  } = compra;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etiqueta: ''
  });

  function setProveedor(proveedor) {
    console.log(proveedor);
    setCompra({
      ...compra,
      proveedor_id: proveedor.value
    })
  }


  const actualizaState = e =>{
    setCompra({
      ...compra,
      [e.target.name] : e.target.value
    })
  } 

  const reiniciarFormulario = () =>{
    setCompra({
      ...compra,
        num_compra: '',
        desc_compra: '',
        importe_total: 0,
        proveedor_id: 0,
        detalle_compra: [],
        historial_inventario: []
    });
    setHistorialInventario([]);
  }

  const submitFormulario = async e =>{
    setMostrarMensaje({
      mostrar: false,
      mensaje: '',
      etiqueta: ''
    });
    
    e.preventDefault();

    if (proveedor_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un proveedor',
        etiqueta: 'error'
      });
      return;
    }
    // if (importe_total === 0){
    //   setMostrarMensaje({
    //     mostrar: true,
    //     mensaje: 'El importe total debe ser mayor a 0',
    //     etiqueta: 'error'
    //   });
    //   return;
    // }
    if(detalle_compra.length ===0 || historial_inventario.length ===0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No ha agregado ningún producto',
        etiqueta: 'error'
      });
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: <div><li className=" fa fa-clock" style={{ fontSize: 20 }}> </li> <label> Guardando, espere un momento...</label></div>,
      etiqueta: 'primary'
    });

    let ruta = 'compras';
    const send = await insertDataJson(ruta,compra);
    console.log(send);
    if(send.status ==='error'){
      setMostrarMensaje({
        mostrar: true,
        mensaje: send.message,
        etiqueta: 'error'
      })
      return;
    }
    if(send.code===201){
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Alta compra [${num_compra}]`, 'Compras', 'label-blue');
      setMostrarMensaje({
        mostrar: true,
        mensaje: <div><li className=" fa fa-check" style={{ fontSize: 20 }}> </li> <label> La compra se ha guardado correctamente.</label></div>,
        etiqueta: 'success'
      });
      reiniciarFormulario();
    }

    console.log('Guardando....');
    console.log(compra);
  }

  return(
    <div className="col-md-12">
      <form className=""
        onSubmit={submitFormulario}
      > 
        <div className="card mb-3">
          <div className="card-header-tab card-header">
            <div className="card-header-title col-md-6">
              NUEVA COMPRA
            </div>
            <div className="text-right col-md-6">
              <button className="mt-1 btn btn-success" style= {{ fontSize: 15}}> <i className="fa fa-floppy-o"></i> Guardar compra</button>
              <p style={{ fontSize: 10, color: 'gray' }}>*Guarde la compra cuando haya agregado todos los productos</p>
            </div>
          </div>
          <div className="card-body">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              <div className = "row">
                <div className="col-md-6">
                  <label>Proveedor(*)</label>
                  <Select 
                    options={proveedores} 
                    onChange={setProveedor}
                  />
                </div>
                <div className="col-md-3">
                  <label>Número compra(*)</label>
                  <input  
                    type="text" 
                    className="mb-2 form-control-sm form-control" 
                    name = "num_compra"
                    value = {num_compra}
                    onChange = {actualizaState}
                    required
                    />
                </div>
                <div className="col-md-3">
                  <label>Fecha compra(*)</label>
                  <input  
                    type="date" 
                    className="mb-2 form-control-sm form-control" 
                    name = "fecha_compra"
                    value = {fecha_compra}
                    onChange = {actualizaState}
                    required
                    />
                </div>
              </div>
              <div className = "row">
                <div className="col-md-8">
                  <label>Descripción</label>
                  <input  
                    type="text" 
                    className="mb-2 form-control-sm form-control" 
                    onChange = {actualizaState}
                    name = "desc_compra"
                    value = {desc_compra}
                    />
                </div>
                <div className="col-md-4">
                  <label>Importe total sin IVA(*)</label>
                  <input  
                    type="number" 
                    className="mb-2 form-control-sm form-control" 
                    onChange = {actualizaState}
                    name = "importe_total"
                    value = {importe_total}
                    required
                    />
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Formulario