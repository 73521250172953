import React, {createContext, useState} from 'react'

export const AppContext = createContext();

const AppProvider = (props) =>{
  //const [dominio, setDominio] = useState('https://stockapi.adaptingweb.com');
  //const [dominio, setDominio] = useState('http://localhost:8000');
  const [dominio, setDominio] = useState('https://acerosapp.adaptingweb.com');
  const [modaleliminar, serModalEliminar] = useState({
    titulo: '',
    desc: '',
    aceptar: false
  });


  var fecha = new Date(); 
  var mes = fecha.getMonth()+1; 
  var dia = fecha.getDate(); 
  var ano = fecha.getFullYear();
  if(dia<10)
    dia='0'+dia; 
  if(mes<10)
    mes='0'+mes 
  fecha = ano+"-"+mes+"-"+dia;

  //Compra
  const [compra, setCompra] = useState({
    num_compra: '',
    desc_compra: '',
    fecha_compra: fecha,
    importe_total: 0,
    proveedor_id: 0,
    status: 0,
    detalle_compra: [],
    precio_sucursal: [],
    historial_inventario: [],
  })

  const [historialinventario, setHistorialInventario] = useState([]);

    //Venta
    const [venta, setVenta] = useState({
      nombre_cliente: '',
      telefono: '-',
      asunto: 'Venta en mostrador',
      fecha_hora_venta: fecha,
      atte: '',
      subtotal: 0,
      iva: 0,
      total: 0,
      utilidad: 0,
      observaciones: '',
      cuanto_paga: 0,
      tipo_pago: 'Efectivo',
      importe_letras: '',
      sucursal_id: 0,
      cliente_id: 0,
      vendedor_id: 0,
      status: 0,
      descuento: 0,
      subtotal_sin_descuento: 0,
      detalle_venta: [],
    })
  
    const [detalleventa, setDetalleVenta] = useState([]);



  return(
    <AppContext.Provider
      value={{
        dominio,
        modaleliminar,
        serModalEliminar,
        compra,
        setCompra,
        historialinventario,
        setHistorialInventario,
        venta,
        setVenta,
        detalleventa,
        setDetalleVenta
      }}
    >
      {props.children}
    </AppContext.Provider>
  )
}

export default AppProvider
