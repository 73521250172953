import React, { useState, Fragment } from 'react'
import { insertData, insertActivity } from '../helpers'
import Mensaje from '../Mensaje'

const Formulario = ({getUsuarios, setUsuario, usuario, sucursales}) =>{
  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const { name, email, password, password_confirmation, tipo, empresa_id } = usuario;

  const actualizaState = e =>{
    setUsuario({
      ...usuario,
      [e.target.name] : e.target.value
    })
  } 


  const reiniciarForm = (e) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Se ha guardado correctamente',
      etiqueta: 'success'
    })
    setUsuario({
      name: '',
      email: '',
      password: '',
      password_confirmation: '',
      status: 1,
      tipo: 'Vendedor',
      empresa_id: 1
    })
    getUsuarios();
  }

  const submitFormulario = async e =>{
    e.preventDefault();

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Guardando....',
      etiqueta: 'primary'
    })

    if(password !== password_confirmation ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Las contraseñas no coinciden',
        etiqueta: 'error'
      })
      return;
    }

    if(name ==='' || email === '' || password ==='' || password_confirmation === '' ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    if( empresa_id === 0 ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Seleccione una sucursal',
        etiqueta: 'error'
      })
      return;
    }

  
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    let formData = new FormData();
    formData.append('name', usuario.name);
    formData.append('email', usuario.email);
    formData.append('tipo', usuario.tipo);
    formData.append('empresa_id', usuario.empresa_id);
    formData.append('password', usuario.password);
    formData.append('password_confirmation', usuario.password_confirmation);
    formData.append('status',usuario.status);


    let ruta = 'usuario-empresa';
    //Guardar
    const send = await insertData(ruta,formData);
    console.log(send)
    if(send.status ==='error'){
      setMostrarMensaje({
        mostrar: true,
        mensaje: send.message,
        etiqueta: 'error'
      })
      return;
    }
    if(send.code===201){
      reiniciarForm();
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Agregó un usuario`, 'Usuarios', 'label-blue');
    }
  }

  return(
    <Fragment>
      <div class="col-md-6">
        <div class="card mb-3">
          <div class="card-header-tab card-header">
            <div class="card-header-title">
              USUARIOS
            </div>
          </div>
          <div class="card-body">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            <form class=""
              onSubmit={submitFormulario}
            > 
              <label>Nombre(*)</label>
              <input  
                type="text" 
                class="mb-2 form-control-sm form-control" 
                name = "name"
                value = {name}
                onChange = {actualizaState}
                required
                />
              <label>Correo(*)</label>
              <input  
                type="email" 
                class="mb-2 form-control-sm form-control" 
                name = "email"
                value = {email}
                onChange = {actualizaState}
                required
                />
              <label>Contraseña(*)</label>
              <input  
                type="password" 
                class="mb-2 form-control-sm form-control" 
                name = "password"
                value = {password}
                onChange = {actualizaState}
                pattern=".{3,}"
                required 
                title="Debe contener mínimo 6 caracteres"
                />
              <label>Confirmar contraseña(*)</label>
              <input  
                type="password" 
                class="mb-2 form-control-sm form-control" 
                name = "password_confirmation"
                value = {password_confirmation}
                onChange = {actualizaState}
                required
                />
                <label>Sucursal</label>
                <select
                  className="form-control"
                  name="empresa_id" 
                  value = {empresa_id}
                  onChange = {actualizaState}
                >
                  <option value ="0">-- Seleccione una sucursal --</option>
                  {
                    sucursales ?
                    sucursales.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_sucursal}
                      </option>
                    ))
                  :null
                  }
                </select>
                <label style={{ marginTop: 10}}>Permisos</label>
                <select 
                  className="form-control"
                  name="tipo" 
                  value = {tipo}
                  onChange = {actualizaState}
                  >
                  <option value = "Vendedor">Vendedor</option>
                  <option value = "Capturista">Capturista</option>
                  <option value = "Gerente">Gerente</option>

                </select>
                <button class="mt-1 btn btn-primary">Guardar usuario</button>
            </form>
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Formulario