import React, { useEffect, useState, useContext } from 'react'
import { getData, insertData, deleteItem, insertActivity } from '../helpers'
import Select from 'react-select';
import Mensaje from '../Mensaje'

const ContainerTransferir = () =>{  
  const [productos, setProductos]= useState([]);
  const [sucursales, setScursales]= useState([]);
  const [inventario, setInventario]= useState([]);
  const [mandarproducto, setMandarProducto] = useState({
    sucursal_origen_id: 0,
    nombre_sucursal_origen: '',
    sucursal_destino_id: 0,
    nombre_sucursal_destino: '',
    producto_id: 0,
    cantidad: 0,
    status: 0
  })
  const { sucursal_origen_id, 
          sucursal_destino_id, 
          producto_id, 
          cantidad, 
          nombre_sucursal_origen,
          nombre_sucursal_destino,
          status
        } = mandarproducto;

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getProductos =  async () =>{
    const send = await getData('productos');
    console.log(send)
    let list_productos = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_productos.push({
          value: item.id,
          label: 'Código: ' + item.codigo + ' - Nombre: '+item.nombre_producto,
          codigo: item.codigo,
          descripcion: item.nombre_producto,
          precio_lista: item.precio_unitario
        })
      })
      console.log(list_productos)
      setProductos(list_productos);
    }
  }

  const getInventario =  async () =>{
    const send = await getData('inventario');
    console.log(send)
    setInventario(send.data);
  }

  const getSucursales =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('sucursales');
    console.log(send)
    setScursales(send.data);
  }

  function seleccionarProducto(detalle_producto) {
    setMandarProducto({
      ...mandarproducto,
      producto_id: detalle_producto.value,
    })
  }

  const actualizaState = e =>{
    setMandarProducto({
      ...mandarproducto,
      [e.target.name] : e.target.value
    })
  } 

  const reiniciarForm = (data) =>{
    setMostrarMensaje({
      mostrar: true,
      mensaje: <div>Se ha transferido el producto correctamente {data.nombre_sucursal_origen}: <b>{data.origen}</b> | {data.nombre_sucursal_destino}: <b>{data.destino}</b></div>,
      etiqueta: 'success'
    })
    setMandarProducto({
      ...mandarproducto,
        sucursal_origen_id: 0,
        nombre_sucursal_origen: '',
        sucursal_destino_id: 0,
        nombre_sucursal_destino: '',
        cantidad: 0,
        status: 0
    })
  }

  const submitFormulario = async e =>{
    e.preventDefault();
    console.log('mandando....')
    if(producto_id ===0 || sucursal_destino_id === 0 || sucursal_origen_id ===0 || cantidad === 0 ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Todos los campos(*) son obligatorios',
        etiqueta: 'error'
      })
      return;
    }

    setMostrarMensaje({
      mostrar: true,
      mensaje: 'Enviando....',
      etiqueta: 'primary'
    })

    if(sucursal_origen_id === sucursal_destino_id){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar sucursales distintas',
        etiqueta: 'error'
      });
      return;
    }

    const cantidad_iventario = inventario.filter(pro=> parseInt(pro.producto_id) === parseInt(producto_id) && parseInt(pro.sucursal_id) === parseInt(sucursal_origen_id))[0];
    console.log(cantidad_iventario)
    if(cantidad_iventario){
      if(parseInt(cantidad_iventario.cantidad_disponible) < parseInt(cantidad)) {
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'No hay cantidad suficiente en inventario existencia: '+ cantidad_iventario.cantidad_disponible,
          etiqueta: 'error'
        })
        return
      }
    }else{
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No se encontro inventario de este producto en la sucursal origen',
        etiqueta: 'error'
      })
      return
    }

    const sucursal_origen = sucursales.filter(suc=> suc.id === parseInt(sucursal_origen_id) )[0];
    const sucursal_destino = sucursales.filter(suc=> suc.id === parseInt(sucursal_destino_id) )[0];

    let formData = new FormData();
    formData.append('sucursal_origen_id', sucursal_origen_id);
    formData.append('nombre_sucursal_origen', sucursal_origen.nombre_sucursal);
    formData.append('sucursal_destino_id', sucursal_destino_id);
    formData.append('nombre_sucursal_destino', sucursal_destino.nombre_sucursal);
    formData.append('producto_id', producto_id);
    formData.append('cantidad', cantidad);
    formData.append('status', status);

      //Guardar
      let ruta = 'historial-transferencia';
      const send = await insertData(ruta,formData);
      console.log(send);
      if(send.status ==='error'){
        setMostrarMensaje({
          mostrar: true,
          mensaje: send.message,
          etiqueta: 'error'
        })
        return;
      }
      if(send.code===201){
        const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
        insertActivity(user.name, `Transferencia de producto [${send.data.nombre_sucursal_destino}]`, 'Transferir', 'label-blue');
        reiniciarForm(send.data);
      }

  }


  useEffect(()=>{
    getSucursales();
    getProductos();
    getInventario();
  },[]);

  return(
    <div class="app-main__inner">           
        <div class="card mb-3">
          <div class="card-header-tab card-header">
            <div class="card-header-title">
              TRANSFERIR PRODUCTO ENTRE SUCURSALES
            </div>
          </div>
          <div class="card-body">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
            <form class=""
              onSubmit={submitFormulario}
            > 
                <label>Producto</label>
                <Select 
                  options={productos} 
                  onChange={seleccionarProducto}
                />
                <label>Cantidad</label>
                <input  
                  type="number" 
                  class="mb-2 form-control-sm form-control" 
                  name = "cantidad"
                  value = {cantidad}
                  onChange = {actualizaState}
                  required 
                  />
                <label>Sucursal Origen</label>
                <select
                  className="form-control"
                  name="sucursal_origen_id" 
                  value = {sucursal_origen_id}
                  onChange = {actualizaState}
                >
                  <option value ="0">-- Seleccione una sucursal --</option>
                  {
                    sucursales ?
                    sucursales.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_sucursal}
                      </option>
                    ))
                  :null
                  }
                </select>
                <label>Sucursal Destino</label>
                <select
                  className="form-control"
                  name="sucursal_destino_id" 
                  value = {sucursal_destino_id}
                  onChange = {actualizaState}
                >
                  <option value ="0">-- Seleccione una destino --</option>
                  {
                    sucursales ?
                    sucursales.map(item=>(
                      <option 
                        key = {item.id}
                        value={item.id}
                        >{item.nombre_sucursal}
                      </option>
                    ))
                  :null
                  }
                </select>
                <button class="mt-4 btn btn-primary">Envíar producto</button>
            </form>
          </div>
        </div>
    </div>
  )
}

export default ContainerTransferir