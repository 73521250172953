import React, { useEffect, useState } from 'react'
import { getData, deleteItem, insertActivity } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'


const ContainerUnidadMedida = () =>{  
  const [unidades, setUnidades]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [unidad, setUnidad] = useState({
    desc_unidad_medida: ''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [imagen, setImagen]= useState(null);

  const getUnidades =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('unidad-medida');
    console.log(send)
    setUnidades(send.data);
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setUnidad(data);
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('unidad-medida/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la unidad',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getUnidades();
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó Unidad de medida [${send.data.desc_unidad_medida}]`, 'Unidad de medida', 'label-red');
    }
  }

  useEffect(()=>{
    getUnidades();

  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getUnidades = {getUnidades}
          setUnidad = {setUnidad}
          unidad = {unidad}

          setActualizar = {setActualizar}
          actualizar = {actualizar}

          setImagen = {setImagen}
          imagen = {imagen}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          unidades = {unidades}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default ContainerUnidadMedida