import React, { useState, useContext } from 'react';
import Select from 'react-select';
import { AppContext } from '../contextApp'
import Mensaje from '../Mensaje'

const Formulario = ({productos, productos2, sucursales}) =>{
  const { 
    compra, 
    setCompra,
    historialinventario,
    setHistorialInventario
   } = useContext(AppContext);
   
  const [buscar, setBuscar] = useState(false);

  const [detallecompra, setDetalleCompra] = useState({
    producto_id: 0,
    nombre_producto: 0,
    cantidad: 0,
    precio_unitario: 0,
    lote: 0,
    fecha_caducidad: '',
    status: 0
  });

  const [valoressucursal, setValoresSucursal] = useState([]);

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etiqueta: ''
  });

  const [statuslote, setStatusLote] = useState(0);
  const [statuscaducidad, setStatusCaducidad] = useState(0);

  function setProducto(producto) {
    console.log(producto);
    setStatusLote(producto.status_lote);
    setStatusCaducidad(producto.status_caducidad);
    setDetalleCompra({
      ...detallecompra,
      producto_id: producto.value,
      nombre_producto: producto.label
    })
  }

  const setProducto2 = e =>{
    setMostrarMensaje({
      mostrar: false
    })

    if(e.target.value !== ''){
      const producto = productos2.filter(item=>item.codigo === e.target.value);
      console.log(producto[0]);
      if(producto[0]){
        setDetalleCompra({
          ...detallecompra,
          producto_id: producto[0].id,
          nombre_producto: producto[0].nombre_producto
        })
        setMostrarMensaje({
          mostrar: true,
          mensaje: 'Clave: '+producto[0].codigo+' Nombre: '+ producto[0].nombre_producto,
          etiqueta: 'success'
        });
        return;
      }
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'El producto no se encuentra capturado',
        etiqueta: 'error'
      });
    }
  }

  const actualizaState = e =>{
    setDetalleCompra({
      ...detallecompra,
      [e.target.name] : e.target.value
    })
  } 

  const actualizaStateSucursal = e =>{
    console.log(e)
    valoressucursal[e.target.name]= {
      cantidad: parseInt(e.target.value),
      precio_unitario: detallecompra.precio_unitario,
      sucursal_id: parseInt(e.target.name),
      status: 0,
      producto_id: detallecompra.producto_id,
      nombre_producto: detallecompra.nombre_producto,
      lote: detallecompra.lote,
      fecha_caducidad: detallecompra.fecha_caducidad,
      nombre_sucursal: e.target.ariaLabel
    }
  } 

  const actualizaBuscar = () =>{
    setBuscar( buscar ? false: true );
    console.log(buscar)
  }

// Agregar a tabla
  const submitFormulario = async e =>{
    setMostrarMensaje({
      mostrar: false,
      mensaje: '',
      etiqueta: ''
    });

    e.preventDefault();

    if (detallecompra.producto_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un producto',
        etiqueta: 'error'
      });
      return;
    }

    const existe_producto = historialinventario.filter(producto => producto.producto_id === detallecompra.producto_id);
    if(existe_producto.length > 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Ya se ha agragado este producto',
        etiqueta: 'error'
      });
      return;
    }

    console.log('Guardando detalle....');

    let lista_nueva_cantidad = [];    
    historialinventario.map(item=>{
      lista_nueva_cantidad.push(item);
    });
    //validar que la cantidad total se capture
    let total_inventario_sucursales = 0;
    valoressucursal.map(item2=>{
      lista_nueva_cantidad.push(item2);
      total_inventario_sucursales = total_inventario_sucursales + parseInt(item2.cantidad);
    });

    if(total_inventario_sucursales < detallecompra.cantidad){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'La cantidad en sucursales no coincide con la cantidad total',
        etiqueta: 'error'
      });
      return;
    }
    setHistorialInventario(lista_nueva_cantidad);

    setCompra({
      ...compra,
      detalle_compra:[
        ...compra.detalle_compra,
            detallecompra
      ],
      historial_inventario: lista_nueva_cantidad
    });
  }

  const InputSucursal = (item) =>{
    let ancho_input = '10%';
    if(sucursales.length > 0){
      ancho_input = 100/sucursales.length + '%';
    }
    return(
      <div style = {{ width: ancho_input, float: 'left', paddingLeft: 5}}>
        <label>{ item.nombre }</label>
        <input  
          type="number" 
          className="mb-2 form-control-sm form-control" 
          name = {item.sucursal_id}
          onChange = {actualizaStateSucursal}
          aria-label = {item.nombre}
          required
          min="0" 
          pattern="^[0-9]+"
          />
      </div>
    )
  }

  return(
    <div className="col-md-12">
      <div className="card mb-3">
        <div className="card-header-tab card-header">
          <div className="card-header-title">
            PRODUCTO
          </div>
        </div>
        <div className="card-body">
          { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
          <form className=""
            onSubmit={submitFormulario}
          > 
            <div className = "row">
              <div className="col-md-6">
              <label>Producto(*) | </label>
                <label>
                   Buscar por nombre 
                  <input
                    name="buscar"
                    type="checkbox"
                    checked={buscar}
                    onChange={actualizaBuscar} 
                  />
                </label>
                {
                  buscar ?
                    <Select 
                      options={productos} 
                      onChange={setProducto}
                    />
                    :
                    <input  
                      className="mb-2 form-control-sm form-control" 
                      name = "Código"
                      value = {detallecompra.clave}
                      onChange = {setProducto2}
                      required
                    />
                }
              </div>
              <div className="col-md-2">
                <label>Cantidad Total(*)</label>
                <input  
                  type="number" 
                  className="mb-2 form-control-sm form-control" 
                  name = "cantidad"
                  value = {detallecompra.cantidad}
                  onChange = {actualizaState}
                  required
                  min="1" 
                  pattern="^[0-9]+"
                  />
              </div>
              <div className="col-md-2">
                <label>Precio compra(*)</label>
                <input  
                  type="number" 
                  className="mb-2 form-control-sm form-control" 
                  name = "precio_unitario"
                  value = {detallecompra.precio_unitario}
                  onChange = {actualizaState}
                  required
                  min="0" 
                  pattern="^[0-9]+"
                  />
              </div>
              <div className="col-md-2">
                <label>Lote</label>
                  <input  
                    type="text" 
                    className="mb-2 form-control-sm form-control" 
                    name = "lote"
                    value = {detallecompra.lote}
                    onChange = {actualizaState}
                    required
                    />
              </div>
            </div>
            <div className="row">
              {
                parseInt(statuscaducidad) === 1 ?
                  <div className="col-md-3 mt-2">
                    <label>Fecha de caducidad</label>
                      <input  
                        type="date" 
                        className="mb-2 form-control-sm form-control" 
                        name = "fecha_caducidad"
                        value = {detallecompra.fecha_caducidad}
                        onChange = {actualizaState}
                        required
                        min="1" 
                        pattern="^[0-9]+"
                        />
                    </div>
                  : null
              }
            </div>
            <div className="row">
              <div className="col-md-12 mt-2">
                {
                  sucursales.length > 0 ?
                    sucursales.map(item=><InputSucursal 
                      nombre = { item.nombre_sucursal}
                      sucursal_id = {item.id}
                      key = {item.id}
                    />) 
                  :
                  null
                }
              </div>
            </div>
              <div className="col-md-12 text-center">
                <button className="mt-1 btn btn-primary"><li className="fa fa-arrow-down"></li> Agregar producto</button>
              </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Formulario