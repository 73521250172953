import React from 'react'

const Footer = () => {
  return(
    <div className="app-wrapper-footer">
      <div className="app-footer">
          <div className="app-footer__inner">
              <div className="app-footer-left">
                  AdaptingStock 2021
              </div>
          </div>
      </div>
    </div>   
  )
}

export default Footer