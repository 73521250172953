import React, { useContext, useState } from 'react';
import { calcularTotal } from '../helpers';
import MaterialTable from 'material-table';
import { AppContext } from '../contextApp'


const Tabla = ()=> {

  const { detalleventa,  setDetalleVenta, venta, setVenta } = useContext(AppContext);

  
  const [columnasdetalleventas, setColumnasDetalleVentas] = useState(
    [
      { title: 'Codigo', field: 'codigo' },
      { title: 'Cantidad', field: 'cantidad' },
      { title: 'Producto', field: 'descripcion' },
      { title: 'PU', field: 'precio_lista' },
      { title: 'Des(%)', field: 'descuento' },
      { title: 'PU Final', field: 'pu_final' },
      { title: 'Importe', field: 'importe_partida' },
      // { title: 'IVA', field: 'iva_partida' },
      // { title: 'Total', field: 'total_partida' }
    ]
  );

  return (
      <div className="col-md-12">
        <MaterialTable
          options={{
            headerStyle:{fontSize:'12px'},
            bodyStyle:{fontSize:'12px'},
            pageSize: 10
          }}
          title="Productos/Ventas"
          columns={columnasdetalleventas}
          data={detalleventa}
          onRowSelected
          editable={{
              onRowUpdate: (newData, oldData) =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataUpdate = [...detalleventa];
                      const index = oldData.tableData.id;
                      if(newData['descuento']>0){
                        newData['pu_final'] = newData['precio_lista'] - (newData['precio_lista'] * (newData['descuento']/100));
                        newData['importe_partida'] = newData['pu_final'] * newData['cantidad'];
                        newData['iva_partida'] = (parseFloat(newData['importe_partida'] * 0.16)).toFixed(2);
                        newData['total_partida'] = (parseFloat(newData['importe_partida'] * 1.16)).toFixed(2); 
                      }else if (newData['cantidad']>0 || newData['precio_lista']>0){
                        newData['pu_final'] = newData['precio_lista'];
                        newData['importe_partida'] = (parseFloat(newData['pu_final'] * newData['cantidad'])).toFixed(2);
                        newData['iva_partida'] = ((parseFloat(newData['pu_final'] * newData['cantidad'])) * (0.16)).toFixed(2);
                        newData['total_partida'] = ((parseFloat(newData['pu_final'] * newData['cantidad'])) * (1.16)).toFixed(2); 
                      }
                      dataUpdate[index] = newData;
                      setDetalleVenta([...dataUpdate]);
                      setVenta({
                        ...venta,
                        detalle_venta: dataUpdate
                      });
                      calcularTotal(venta, setVenta, dataUpdate);
                      resolve();
                    }, 1000)
                  }),
              onRowDelete: oldData =>
                  new Promise((resolve, reject) => {
                    setTimeout(() => {
                      const dataDelete = [...detalleventa];
                      const index = oldData.tableData.id;
                      dataDelete.splice(index, 1);
                      setDetalleVenta([...dataDelete]);
                      setVenta({
                        ...venta,
                        detalle_venta: dataDelete
                      });
                      calcularTotal(venta, setVenta, dataDelete);
                      resolve();
                    }, 1000)
                  })
          }}
      />
      </div>
  );
}

export default Tabla
