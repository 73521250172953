import React, { Fragment, useState, useEffect, useContext } from 'react';
import { getData } from '../helpers'
import { AppContext } from '../contextApp'

import Header from '../Template/Header'
const ListaSucursales = () =>{
  const { dominio } = useContext(AppContext);
  const [sucursales, setSucursales] = useState([]);

  const getSucursales = async () =>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    if(user){
      const send = await getData('sucursals-empresa/'+user.empresa_id);
      if(send.code === 200){
        setSucursales(send.data);
        console.log(send)
        return;
      }
    }
    window.location = '/login'

  }

  const seleccionSucursal = (id, nombre) =>{
    let datos_sucursal = {
      sucursal_id: id,
      nombre: nombre
    }
    datos_sucursal = JSON.stringify(datos_sucursal)
    localStorage.setItem('STOCK_SUCURSAL',datos_sucursal);
    window.location = '/home'
  }

  useEffect(()=>{
    getSucursales();
  },[]);
  
  const CardSucursal = ({data}) =>{
    return(
      <div className="card" style={{marginTop:20}}>
        <div className="card-header">{data.nombre}</div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-6">
              {
                data.foto ?
                <img style={{borderRadius: 20}} width="200" src={dominio+'/'+data.foto}></img>
                : null
              }
            </div>
            <div className="col-md-6">
              <p>{data.descripcion}</p>
              <p>{data.direccion}</p>
              <button onClick={()=>seleccionSucursal(data.id, data.nombre)} className="mt-1 btn btn-primary">Seleccionar sucursal <li className="fa fa-arrow-circle-right"></li></button>
            </div>
          </div>
        </div>
      </div>
    )
  }
  return(
    <Fragment>
      <Header />
      <div className="row justify-content-center">
          <div className="col-md-6">
            {
              sucursales ?
              sucursales.map(item=>(
                <CardSucursal 
                  data = {item}
                />
              ))
              : null
            }
            
          </div>
      </div>
    </Fragment>
  )
}

export default ListaSucursales

