import React, { useEffect, useState, useContext } from 'react'
import { getData, deleteItem, insertActivity } from '../helpers'
import Formulario from './Formulario'
import Tabla from './Tabla'
import { AppContext } from '../contextApp'

const ContainerCategorias = () =>{  
  const { dominio } = useContext(AppContext);
  const [categorias, setCategorias]= useState([]);
  const [botoneliminar, setBotonEliminar] = useState(false);
  const [categoria, setCategoria] = useState({
    desc_categoria: ''
  });
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });
  const [imagen, setImagen]= useState(null);

  const getCategorias =  async () =>{
    //const sucursal =  await JSON.parse(localStorage.getItem('STOCK_SUCURSAL'));
    const send = await getData('categorias');
    console.log(send)
    setCategorias(send.data);
  }

  const [actualizar, setActualizar] = useState(false);
  const actualiza = async (data) =>{
    setActualizar(true);
    setCategoria(data);
    setImagen(dominio+'/'+data.foto)
  }

  const eliminaItem = async (data) =>{
    console.log(data)
    const send = await deleteItem('categorias/'+data.id);
    console.log(send);
    if(send.code===423){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: send.error,
        etiqueta: 'error'
      })
    }
    if(send.code===200){
      setMostrarMensaje2({
        mostrar: true,
        mensaje: 'Se ha eliminado la categoría',
        etiqueta: 'danger'
      })
      setBotonEliminar(false);
      getCategorias();
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Eliminó categoría [${send.data.desc_categoria}]`, 'Categoría', 'label-red');
    }
  }

  useEffect(()=>{
    getCategorias();

  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <Formulario 
          getCategorias = {getCategorias}
          setCategoria = {setCategoria}
          categoria = {categoria}

          setActualizar = {setActualizar}
          actualizar = {actualizar}

          setImagen = {setImagen}
          imagen = {imagen}
        />
        <Tabla 
          mostrarmensaje2 = {mostrarmensaje2}

          categorias = {categorias}
          actualiza = {actualiza}

          botoneliminar = {botoneliminar}
          setBotonEliminar = {setBotonEliminar}

          eliminaItem = {eliminaItem}
        />
      </div>
    </div>
  )
}

export default ContainerCategorias