import React, { useState, useEffect } from 'react'
import { getData, insertActivity, updateItem } from '../helpers'
import Tabla from './Tabla'
import ModalModificarInventario from './ModalModificarInventario'

const ReporteInventario = () =>{
  const [inventario, setInvetario] = useState([]);
  const [inventarioid, setInventarioId] = useState(0);
  const [nombreproducto, setNombreProducto] = useState('');
  const [cantidad, setCantidad] = useState({
    cantidad_disponible: 0
  })
  const getInventario =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    const send = await getData('inventario/'+user.empresa_id);
    console.log(send)
    if(send.code === 200){
      setInvetario(send.data);
    }
    insertActivity(user.name, `Consultó inventario`, 'Reporte inventario', 'label-blue');
  }

  const actualizar = async()=>{
    if(inventarioid === 0){
      return;
    }
    const send = await updateItem('inventario/'+inventarioid,cantidad);
    console.log(send);
    if(send.code === 200){
      getInventario();
      setCantidad({
        cantidad_disponible: 0
      })
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Modificación de inventario [${nombreproducto}]`, 'Reporte inventario', 'label-orange');
    }

  }

  useEffect(()=>{
    getInventario();
  },[]);
  return(
    <div className="app-main__inner">        
      <ModalModificarInventario 
        actualizar = {actualizar}
        cantidad = {cantidad}
        setCantidad = {setCantidad}
        nombreproducto = {nombreproducto}
        />
      <div className="row">
        <Tabla 
          inventario = {inventario}
          setInventarioId = { setInventarioId }
          setCantidad = { setCantidad }
          setNombreProducto = {setNombreProducto}
        />
      </div>
    </div>
  )
}

export default ReporteInventario