import React, { useState, useEffect, Fragment } from 'react'
import { useLocation } from 'react-router-dom'
import { getData } from '../helpers'
import { useBarcode } from 'react-barcodes';

const CodigoBarras = () =>{
  const [ticket, setTicket] = useState([]);
  const [code, setCode] = useState('0');

  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  let query = useQuery();


  const { inputRef } = useBarcode({
    value: code,
    options: {
      background: 'white',
    }
  });

  const getCodigoBarras =  async () =>{
    let codigo = query.get("codigo");
    await setCode(codigo);
    if(code !== '0' && code !== 0){
      window.print();
    }
  }

  useEffect(()=>{
    getCodigoBarras();
  },[code]);

  return(
    <div style={{ width: 240 }} className="ticket">
      <div className="text-center">
        <img height="80" width="200" style={{ alignSelf: 'center', display: code === '0' ? 'none' : '' }} ref={inputRef} />
      </div>
    </div>
  )
}

export default CodigoBarras