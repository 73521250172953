import React, { useState, useEffect } from 'react'
import { getData, insertActivity } from '../helpers'
import Tabla from './TablaProductos'

const ReporteInventario = () =>{
  const [inventario, setInvetario] = useState([]);

  const getProductos =  async () =>{
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    const send = await getData('productos');
    console.log(send)
    if(send.code === 200){
      setInvetario(send.data);
    }
    insertActivity(user.name, `Consultó productos`, 'Lista de productos', 'label-blue');
  }

  useEffect(()=>{
    getProductos();
  },[]);
  return(
    <div className="app-main__inner">           
      <div className="row">
        <Tabla 
          inventario = {inventario}
        />
      </div>
    </div>
  )
}

export default ReporteInventario