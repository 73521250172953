import React, { useEffect, useState, useContext } from 'react'
import { getData, insertActivity } from '../helpers'
import FormularioVenta from './FormularioVenta'
import FormularioDetalleVenta from './FormularioDetalleVenta'
import TablaDetalle from './TablaDetalle'
import Total from './Total'
import { AppContext } from '../contextApp'

const ContainerCategorias = () =>{  
  const { dominio } = useContext(AppContext);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const [preciosucursales, setPrecioSucursales] = useState([]);
  const [mostrarmensaje2, setMostrarMensaje2]= useState({
    mostrar: false,
    mensaje: '',
    etique: ''
  });

  const getClientes =  async () =>{
    const send = await getData('clientes');
    console.log(send)
    let list_clientes = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_clientes.push({
          value: item.id,
          label: item.nombre_comercial,
          telefono: item.telefono
        })
      })
      setClientes(list_clientes);
    }
  }

  const getProductos =  async () =>{
    const send = await getData('productos');
    console.log(send)
    let list_productos = [];
    if(send.code === 200){
      send.data.map(item=>{
        list_productos.push({
          value: item.id,
          label: 'Código: ' + item.codigo + ' - Nombre: '+item.nombre_producto,
          codigo: item.codigo,
          descripcion: item.nombre_producto,
          precio_lista: item.precio_unitario
        })
      })
      console.log(list_productos)
      setProductos(list_productos);
    }
  }


  const getCostosProductosSucursal =  async () =>{
    const send = await getData('precios-sucursales');
    console.log(send)
    if(send.code === 200){
      setPrecioSucursales(send.data);
    }
  }


  useEffect(()=>{
    getClientes();
    getProductos();
    getCostosProductosSucursal();
  },[]);

  return(
    <div className="app-main__inner">           
      <div className="row">
        <FormularioVenta 
          clientes = {clientes}
        />
        <FormularioDetalleVenta
          productos = {productos}
          preciosucursales = {preciosucursales}
        />
        <TablaDetalle
        />
        <Total
        />
      </div>
    </div>
  )
}

export default ContainerCategorias