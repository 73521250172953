import React, { useState, useContext } from 'react';
import Select from 'react-select';
import { insertDataJson, insertActivity } from '../helpers';
import { AppContext } from '../contextApp'
import Mensaje from '../Mensaje'
import ModalPagar from './ModalPagar'

const Formulario = ({clientes}) =>{
  const { 
    venta,
    detalleventa,
    setVenta,
    setDetalleVenta
   } = useContext(AppContext);

  const [mostrarmensaje, setMostrarMensaje]= useState({
    mostrar: false,
    mensaje: '',
    etiqueta: ''
  });

  async function setCliente (cliente) {
    console.log(cliente);
    const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
    setVenta({
      ...venta,
      cliente_id: cliente.value,
      nombre_cliente: cliente.label,
      telefono: cliente.telefono,
      vendedor_id: user.id,
      sucursal_id: user.empresa_id,
      status: 0
    })
  }

  const reiniciarFormulario = () =>{
    setDetalleVenta([]);
    setVenta({
      ...venta,
        detalle_venta: []
    })
  }

  const submitFormulario = async e =>{
    setMostrarMensaje({
      mostrar: false,
      mensaje: '',
      etiqueta: ''
    });
    
    e.preventDefault();

    if (venta.cliente_id === 0){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'Debe seleccionar un cliente',
        etiqueta: 'error'
      });
      return;
    }

    if(detalleventa.length ===0 ){
      setMostrarMensaje({
        mostrar: true,
        mensaje: 'No ha agregado ningún producto',
        etiqueta: 'error'
      });
      return;
    }
    setMostrarMensaje({
      mostrar: true,
      mensaje: <div><li className=" fa fa-clock" style={{ fontSize: 20 }}> </li> <label> Guardando, espere un momento...</label></div>,
      etiqueta: 'primary'
    });

    venta['detalle_venta'] = detalleventa;

    let ruta = 'ventas';
    const send = await insertDataJson(ruta,venta);
    console.log(send);
    if(send.status ==='error'){
      setMostrarMensaje({
        mostrar: true,
        mensaje: send.message,
        etiqueta: 'error'
      })
      return;
    }
    if(send.code===201){
      const user =  await JSON.parse(localStorage.getItem('STOCK_USER'));
      insertActivity(user.name, `Realizó la venta [${send.data.id}]`, 'Ventas', 'label-blue');
      setMostrarMensaje({
        mostrar: true,
        mensaje: <div><li className=" fa fa-check" style={{ fontSize: 20 }}> </li> <label> La venta se ha guardado correctamente.</label></div>,
        etiqueta: 'success'
      });
      let venta_id = send.data.id;
      window.open('/ticket?id='+venta_id, '_blank');
      window.location.reload();
    }
  }

  return(
    <div className="col-md-12">
      <form className=""
        onSubmit={submitFormulario}
      > 
        <ModalPagar />
        <div className="card mb-3">
          <div className="card-header-tab card-header">
            <div className="card-header-title col-md-6">
              NUEVA VENTA
            </div>
            <div className="text-right col-md-6">
              <div className="row">
                <div className="col-md-12">
                  <button type="button" className="mt-1 btn btn-success" data-toggle="modal" data-target="#modalpagar"  style= {{ fontSize: 15}}> <i className="fa fa-floppy-o"></i> Guardar venta</button>
                  {/* <button className="mt-1 btn btn-success" style= {{ fontSize: 15}}> <i className="fa fa-floppy-o"></i> Guardar venta</button> */}
                  <p style={{ fontSize: 10, color: 'gray' }}>*Guarde la venta cuando haya agregado todos los productos</p>
                </div>
              </div>
            </div>
          </div>
          <div className="card-body">
            { mostrarmensaje.mostrar ? <Mensaje mensaje = {mostrarmensaje.mensaje} etiqueta = {mostrarmensaje.etiqueta} /> : null}
              <div className = "row">
                <div className="col-md-6">
                  <label>Cliente(*)</label>
                  <Select 
                    styles= {{ zIndex: 1000, position: 'absolute' }}
                    options={clientes} 
                    onChange={setCliente}
                  />
                </div>
                <div className="col-md-3">
                  <label>Teléfono</label>
                  <input  
                    type="text" 
                    className="mb-2 form-control-sm form-control" 
                    name = "telefono"
                    value = {venta.telefono}
                    disabled
                    />
                </div>
                <div className="col-md-3">
                  <label>Fecha venta(*)</label>
                  <input  
                    type="date" 
                    className="mb-2 form-control-sm form-control" 
                    name = "fecha_compra"
                    value = {venta.fecha_hora_venta}
                    disabled
                    />
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  )
}

export default Formulario